import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import Store from '@/store';
import Home from '../views/Home.vue';
import Show from '../views/Show.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/:citizenid', // citizenid = citizen public key
    name: 'Home II',
    component: Home,
    props: { type: 'Wizard' },
    async beforeEnter(to, from, next: () => void) {
      localStorage.citizenType = 'Wizard';
      localStorage.citizenId = to.params.citizenid;
      readParams();
      await Store.dispatch('FETCH_CONFIG', localStorage.citizenId).catch(error => {
        console.error(error);
      });
      await Store.dispatch('FETCH_CATEGORYGROUPS', localStorage.citizenId).catch(error => {
        console.error(error);
      });
      next();
    },
  },
  {
    path: '/:citizenid/show/', // citizenid = citizen public key
    name: 'Show',
    component: Show,
    props: { type: 'Show' },
    async beforeEnter(to, from, next: () => void) {
      localStorage.citizenType = 'Show';
      localStorage.citizenId = to.params.citizenid;
      localStorage.issueId = to.query.id;
      await Store.dispatch('FETCH_CONFIG', localStorage.citizenId).catch(error => {
        console.error(error);
      });
      await Store.dispatch('FETCH_ISSUE', { issueId: encodeURIComponent(localStorage.issueId), citizenId: localStorage.citizenId }).catch(
        error => {
          console.error(error);
        }
      );
      next();
    },
  },
];

const readParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('email')) {
    sessionStorage.email = urlParams.get('email');
  }
  if (urlParams.get('name')) {
    sessionStorage.name = urlParams.get('name');
  }
  if (urlParams.get('phone')) {
    sessionStorage.phone = urlParams.get('phone');
  }
  if (urlParams.get('notify')) {
    sessionStorage.notify = urlParams.get('notify');
  } else {
    sessionStorage.notify = 'no';
  }
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
