export default class LookupItem {
  id: string = '';
  name: string = '';
}

export class LookupFormValue extends LookupItem {
  selected: boolean = false;
}

export class LookupIssue extends LookupItem {
  locationNear: string = '';
  statusId: string = '';
  createdDate: string = '';
}

export class LookupFieldValue {
  fieldId: string = '';
  value: string = '';
}
