import { render, staticRenderFns } from "./SwecoSelect.vue?vue&type=template&id=8f963f60&scoped=true&"
import script from "./SwecoSelect.vue?vue&type=script&lang=ts&"
export * from "./SwecoSelect.vue?vue&type=script&lang=ts&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./SwecoSelect.vue?vue&type=style&index=1&id=8f963f60&prod&scoped=true&lang=css&"
import style2 from "./SwecoSelect.vue?vue&type=style&index=2&id=8f963f60&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f963f60",
  null
  
)

export default component.exports