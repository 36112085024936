




















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { MessageBus } from '@/components/MessageBus';
import Attachment, { UploadAttachmentsRequest } from '@/models/attachment';
import SwecoSelect from '@/components/input/SwecoSelect.vue';
import SwecoModal from '@/components/input/SwecoModal.vue';
import httpClient, { BASE_API_URL } from '@/networking/httpclient';

@Component({
  components: {
    SwecoModal,
    SwecoSelect,
  },
})
export default class SwecoUpload extends Vue {
  @Prop({ default: false }) multi!: boolean;
  @Prop({ default: undefined }) value!: Attachment;
  @Prop({ default: () => null }) values!: Attachment[];
  @Prop({ default: undefined }) heading!: string;
  @Prop({ default: false }) upload!: boolean;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: '' }) title!: string;

  loading: boolean = false;
  loadingFiles: Attachment[] = [];
  validationError: string = '';
  error: string = '';
  disableValidation: boolean = false;
  attachmentUrl: string = '';

  get getAttachmentBaseUrl() {
    //@ts-ignore
    return ATTACHMENT_VIEW_URL;
  }
  get isRequired() {
    return this.required;
  }

  created() {
    MessageBus.$on('validate', () => {
      this.validateFiles();
      this.validateScrollTo();
    });
    MessageBus.$on('resetValidation', () => {
      this.validationError = '';
      const inputField = this.$refs.inputField as HTMLElement;
      if (inputField != null) {
        inputField.setAttribute('aria-invalid', 'false');
      }
    });
  }

  getFileSize(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  handleFiles(e: any) {
    this.loading = true;
    this.uploadFiles(e.target);
  }

  removeImage(index: number) {
    this.values.splice(index, 1);
  }

  fileToByteArray(file: any) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        const fileByteArray: any[] = [];
        reader.readAsArrayBuffer(file);
        reader.onloadend = evt => {
          if (reader.readyState == FileReader.DONE) {
            if (evt && evt.target) {
              // tslint:disable-next-line:one-variable-per-declaration
              const arrayBuffer = evt.target.result as ArrayBuffer,
                array = new Uint8Array(arrayBuffer);
              for (const byte of array) {
                fileByteArray.push(byte);
              }
            }
          }
          resolve(fileByteArray);
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  async uploadFiles(fileInput: any) {
    const inputField = this.$refs.inputField as HTMLElement;
    if (inputField != null) {
      inputField.setAttribute('aria-invalid', 'false');
    }

    const files = fileInput.files;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < files.length; i++) {
      if (files[i].size < 1024000 * 15) {
        if (/\.(gif|jpg|jpeg|png|pdf)$/i.test(files[0].name)) {
          const attachment = new Attachment();
          attachment.fileName = files[i].name;
          attachment.contentType = files[i].type;
          attachment.content = await this.fileToByteArray(files[i]);
          attachment.fileSize = files[i].size;
          this.loadingFiles.push(attachment);
        } else {
          this.loading = false;
          this.validationError = this.$t.citizenTranslations.citizenDetailsAllowedFiletypes;
          if (inputField != null) {
            inputField.setAttribute('aria-invalid', 'true');
          }
          fileInput.value = null;
          return;
        }
      } else {
        this.loading = false;
        this.validationError = this.$t.citizenTranslations.citizenDetailsAllowedFiletypes;
        if (inputField != null) {
          inputField.setAttribute('aria-invalid', 'true');
        }
        fileInput.value = null;
        return;
      }
    }

    if (this.loadingFiles.length > 0) {
      const req = new UploadAttachmentsRequest();
      req.attachments = this.loadingFiles;
      httpClient
        .put(BASE_API_URL + 'attachments/', req)
        .then(response => {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < response.data.attachments.length; i++) {
            const a = response.data.attachments[i];
            a.fileSize = this.loadingFiles[i].fileSize;
            a.fileName = this.loadingFiles[i].fileName;
            a.contentType = this.loadingFiles[i].contentType;
            this.values.push(a);
          }
          this.validationError = '';
          if (inputField != null) {
            inputField.setAttribute('aria-invalid', 'false');
          }
          this.loadingFiles = [];
          this.loading = false;
          fileInput.value = null;
        })
        .catch(resp => {
          this.validationError = resp;
          if (inputField != null) {
            inputField.setAttribute('aria-invalid', 'true');
          }
          fileInput.value = null;
        });
    }
  }

  blur() {
    this.$emit('blur');
    this.validateFiles();
  }

  get requireText() {
    return this.isRequired
      ? '* <span style="font-size: 10px;">(' + this.$t.citizenTranslations.citizenDetailsErrorFileRequired + ')</span>'
      : '';
  }

  validateFiles() {
    if (this.validationError !== '') {
      return;
    }

    const inputField = this.$refs.inputField as HTMLElement;
    if (inputField != null) {
      inputField.setAttribute('aria-invalid', 'false');
    }

    if (this.required) {
      if (this.values.length == 0) {
        this.validationError = this.$t.citizenTranslations.citizenDetailsErrorFileRequired;
        if (inputField != null) {
          inputField.setAttribute('aria-invalid', 'true');
        }
      }
    }
  }

  validateScrollTo() {
    if (this.validationError) {
      setTimeout(() => {
        (this.$refs.inputField as any).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 10);
    }
  }

  public setDisableValidation(disable: boolean) {
    //Hvis vi ønsker at fjerne valideringen (og dermed stjernen) på baggrund af et andet input, er det tilsyneladende ikke muligt at fjerne en validation fra opsætningen når vi bruger typescript
    //i opsætningen kan man ikke tilgå komponent instansen, og i komponent instancen er der ikke nogen officielt vuelidate måde at tilføje/fjerne en validering. Det er måske muligt med et grimt hack
    //men så hellere denne løsning.
    this.disableValidation = disable;
  }
}
