









































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { MessageBus } from '@/components/MessageBus';
import { Guid } from 'guid-typescript';

@Component
export default class SwecoSelect extends Vue {
  @Prop({ default: 'id' }) trackBy!: string;
  @Prop({ default: 'name' }) label!: string;
  @Prop({ default: '' }) tooltipProperty!: string;
  @Prop({ default: undefined }) heading!: string;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) headingDisabled!: boolean;
  @Prop({ default: false }) multiple!: boolean;
  @Prop({ default: () => [] }) options!: any[];
  @Prop({ default: () => [] }) value!: any[] | any;
  @Prop({ default: null }) validations!: vuelidate.Params;
  @Prop({ default: true }) autoSort!: boolean;
  @Prop() customLabel!: (option: any) => string;
  @Prop() asyncFindProp!: (query: string) => Promise<void>;
  @Prop({ default: false }) optionLink!: boolean;
  @Prop({ default: false }) taggable!: boolean;
  @Prop({ default: false }) resetAfterSelection!: boolean;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: 'label' }) sortValue!: string;
  /**
   * @description When set to true, the component will emit objects instead of strings
   */
  @Prop({ default: false }) objectBased!: boolean;
  /**
   * @note Requires multiple and objectBased to be true
   */
  @Prop({ default: false }) priority!: boolean;
  /**
   * @note Requires priority to be to true
   */
  @Prop({ default: 1 }) priorityStartIndex!: string;
  /**
   * @note Requires priority to be to true
   */
  @Prop({ default: 'prioritet' }) priorityLabel!: string;

  validationError: string = '';
  localoptions: any[] = [];
  isLoading: boolean = false;
  localValue: any[] | any = this.multiple ? [] : {};
  queryTimer!: any;
  STANDARD_DELAY: number = 300;
  originalValue: any | null = null;
  forceNewValue: string = '';
  open: boolean = false;

  tempId: string = 'select' + Guid.create().toString();
  noResults: string = this.$t.citizenTranslations.citizenDetailsNoResults;
  emptyList: string = this.$t.citizenTranslations.citizenDetailsEmptyList;

  get getNoResults() {
    return this.noResults;
  }

  get getEmptyList() {
    return this.emptyList;
  }

  @Watch('value')
  onValueChange(newValue: any[] | any) {
    if (this.forceNewValue) {
      this.setupLocalValue(this.forceNewValue);
    } else {
      this.setupLocalValue(newValue);
    }
    this.forceNewValue = '';
  }

  @Watch('options')
  onOptionsChange(newValue: any[] | any) {
    if (this.forceNewValue) {
      this.setupLocalValue(this.forceNewValue);
    } else {
      this.setupLocalValue(this.value);
    }

    this.forceNewValue = '';
  }

  filterInactiveOptions() {
    const list = [...this.options].filter(
      x => x.aktiv === undefined || x.aktiv === true || (this.localValue ? this.localValue[this.trackBy] == x[this.trackBy] : false)
    );
    //const list = [...this.options];
    if (this.originalValue) {
      //Hvis den oprindelige inaktive værdi er filtreret fra i listen (da den er inaktiv), tilføjer vi den igen så brugeren har mulighed for at vælge den igen
      if (this.multiple) {
        this.originalValue.forEach((orgValue: any) => {
          const orignalValueAlreadyExists = list.find(x => x[this.trackBy] === orgValue[this.trackBy]);
          if (!orignalValueAlreadyExists) {
            list.push(orgValue);
          }
        });
      } else {
        const orignalValueAlreadyExists = list.find(x => x[this.trackBy] === this.originalValue[this.trackBy]);
        if (!orignalValueAlreadyExists) {
          list.push(this.originalValue);
        }
      }
    }
    return list;
  }

  mounted() {
    setTimeout(() => {
      const field = document.getElementById(this.tempId);
      if (field) {
        field.setAttribute('autocomplete', 'off');
      }
    }, 1000);

    this.setupLocalValue(this.value);
  }
  created() {
    MessageBus.$on('validate', () => {
      this.validateInput();
    });
    MessageBus.$on('resetValidation', () => {
      this.validationError = '';
      const multiselectField = this.$refs.multiselectField as Vue & { $el: () => any };
      if (multiselectField != null) {
        multiselectField.$el.setAttribute('aria-invalid', 'false');
      }
      this.originalValue = null;
    });
  }

  handleNewOption(option: string) {
    //TODO not tested when this.objectBased = true;

    const item = {
      id: '' + this.options.length,
      kode: '',
      navn: option,
      beskrivelse: '',
    };
    const newOptions = [...this.options, item];
    this.$emit('update:options', newOptions);

    if (this.value instanceof Array) {
      const newValue = [...this.value, option];
      this.$emit('update:value', newValue);
      this.$emit('input', newValue);
    } else {
      this.$emit('update:value', option);
      this.$emit('input', option);
    }
    this.forceNewValue = option;
  }

  asyncFind(query: string) {
    if (this.asyncFindProp) {
      this.isLoading = true;
      this.startQueryTimer(() => {
        this.asyncFindProp(query).finally(() => {
          this.isLoading = false;
        });
      }, this.STANDARD_DELAY);
    }
  }

  startQueryTimer(callback: () => void, delay: number) {
    if (this.queryTimer != null) {
      clearTimeout(this.queryTimer);
    }
    this.queryTimer = setTimeout(function () {
      callback();
    }, delay);
  }

  get getOptions() {
    this.localoptions = this.filterInactiveOptions();
    if (this.autoSort && this.localoptions) {
      this.sortItems();
    }
    return this.localoptions;
  }

  sortItems() {
    if (this.value == '' || this.value == null) {
      return this.localoptions.sort((a: any, b: any) => {
        return a[this.sortValue] < b[this.sortValue] ? -1 : a[this.sortValue] > b[this.sortValue] ? 1 : 0;
      });
    }
    // this.localoptions.sort((a: any, b: any) => {
    //   return a[this.label].toLowerCase() < b[this.label].toLowerCase()
    //     ? -1
    //     : a[this.label].toLowerCase() > b[this.label].toLowerCase()
    //     ? 1
    //     : 0;
    // });
  }

  setPriority(newValue: any[]) {
    return newValue
      .sort((a: any, b: any) => a[this.priorityLabel] - b[this.priorityLabel])
      .map((item: any, index: any) => {
        item[this.priorityLabel] = this.priorityStartIndex + index;
        return item;
      });
  }

  onChange(newValue: any[] | any) {
    // If the option (from the third-party multiselect library) to clear the input field after
    // selection is triggered the "newValue" provided to this method is null because it happens
    // after the underlying value has been cleared. So we simply bail in this case.
    if (newValue === null) {
      if (!this.multiple && this.value != '') {
        //value indeholder stadig originale værdi efter valg af andet
        newValue = this.getOptions.find(v => v != null && this.value.id == v[this.trackBy]);

        if (this.priority) {
          newValue = this.setPriority(newValue);
        }

        this.localValue = newValue;
      }
      return;
    }

    if (this.priority) {
      newValue = this.setPriority(newValue);
    }

    if (this.multiple) {
      if (this.objectBased) {
        this.$emit('input', [...newValue]);
      } else {
        this.$emit(
          'input',
          [...newValue].map(v => v[this.trackBy])
        );
      }
    } else {
      if (this.objectBased) {
        const value = newValue ? newValue : null;
        this.$emit('input', value);
      } else {
        const value = newValue ? newValue[this.trackBy] : null;
        this.$emit('input', value);
      }
    }

    if (this.autoSort && this.localoptions) {
      this.sortItems();
    }

    this.$emit('select');
  }

  setupLocalValue(newValue: any[] | any) {
    if (this.multiple && newValue) {
      if (this.objectBased) {
        // Merge properties of value and options.
        this.localValue = this.options
          .filter(option => newValue.find((value: any) => value[this.trackBy] == option[this.trackBy]) !== undefined)
          .map(option => {
            const value = newValue.find((value: any) => value[this.trackBy] == option[this.trackBy]);
            return { ...value, ...option };
          });
      } else {
        this.localValue = this.options.filter(v => newValue.find((x: any) => x == v[this.trackBy]) !== undefined);
      }
    } else {
      if (this.objectBased) {
        this.localValue = this.options.find(v => v != null && newValue && newValue[this.trackBy] == v[this.trackBy]);
      } else {
        this.localValue = this.options.find(v => v != null && newValue == v[this.trackBy]);
      }
    }

    if (!this.originalValue && this.localValue && (Array.isArray(this.localValue) ? this.localValue.length > 0 : true)) {
      this.originalValue = this.localValue;
    }

    if (this.validationError) {
      this.validateInput();
    }
  }

  validateInput() {
    this.open = false;
    this.validationError = '';
    const multiselectField = this.$refs.multiselectField as Vue & { $el: () => any };
    if (multiselectField != null) {
      multiselectField.$el.setAttribute('aria-invalid', 'false');
    }

    if (this.validations) {
      if (!this.validationError && this.validations.required === false) {
        this.validationError = this.$t.citizenTranslations.citizenDetailsErrorRequired;
        if (multiselectField != null) {
          multiselectField.$el.setAttribute('aria-invalid', 'true');
        }
      }
    }
    if (this.required && this.localValue == null) {
      this.validationError = this.$t.citizenTranslations.citizenDetailsErrorRequired;
      if (multiselectField != null) {
        multiselectField.$el.setAttribute('aria-invalid', 'true');
      }
    }
  }

  get isRequired() {
    return (this.validations != null && this.validations.$params.required != null && this.validations.$params.required) || this.required;
  }

  get requireText() {
    return this.isRequired
      ? '* <span style="font-size: 10px;">(' + this.$t.citizenTranslations.citizenDetailsErrorRequired + ')</span>'
      : '';
  }

  get titlehover() {
    let title = '';

    if (this.localValue != undefined && this.disabled) {
      if (this.localValue.length != undefined) {
        for (const localValue of this.localValue) {
          if (title != '') {
            title += ', ';
          }
          title += localValue[this.label];
        }
      } else {
        title = this.localValue[this.label];
      }
    }
    return title;
  }
}
