






























import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { HttpResponse } from '@/networking/interfaces';
import SwecoModal from '@/components/input/SwecoModal.vue';
import AddresObject from '@/models/addressObject';
import ActiveItem from '@/models/activeItem';

@Component({
  components: {
    SwecoModal,
  },
})
export default class SelectAccessAddress extends Vue {
  @Prop() show!: boolean;

  @Watch('show')
  setShow() {
    this.showDialog = this.show;
  }

  @Getter('getActiveItem')
  activeItem!: ActiveItem;

  selectedIndex: number = -1;
  showDialog: boolean = false;
  accessAddresses: AddresObject[] = [];

  get setHeader() {
    return this.$t.citizenTranslations.citizenDetailsSelectAccessAddres;
  }

  setIndex(index: number) {
    this.selectedIndex = index;
  }

  setProp(newValue: boolean) {
    this.showDialog = newValue;
    this.$emit('show', this.showDialog);
    this.$emit('update:show', this.showDialog);
  }

  setAddress() {
    this.setProp(false);
    if (this.selectedIndex == -1) {
      this.activeItem.addressObject.valid = false;
    } else {
      const result = this.accessAddresses[this.selectedIndex];
      this.activeItem.addressObject.altLocationNear = result.locationNear;
      this.activeItem.addressObject.addressObject = result.addressObject;
      this.activeItem.addressObject.valid = true;
    }
  }

  formatDate(date: string) {
    return new Date(date).toLocaleString(localStorage.lang);
  }
}
