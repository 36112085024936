













































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SwecoInput from '@/components/input/SwecoInput.vue';
import SwecoCheckbox from '@/components/input/SwecoCheckbox.vue';
import SwecoRadioButton from '@/components/input/SwecoRadioButton.vue';
import SwecoSelect from '@/components/input/SwecoSelect.vue';
import SwecoTextarea from '@/components/input/SwecoTextarea.vue';
import SwecoDatePicker from '@/components/input/SwecoDatePicker.vue';
import SwecoImageRadioButton from '@/components/input/SwecoImageRadioButton.vue';
import SwecoImageCheckbox from '@/components/input/SwecoImageCheckbox.vue';
import Form, { Field, Container } from '@/models/form';
import { MessageBus } from '@/components/MessageBus';
import { required, minLength, numeric, maxLength, helpers, requiredIf, email } from 'vuelidate/lib/validators';
import validation from '@/models/validation';
import ActiveItem, { FieldValue, FormFieldValues } from '@/models/activeItem';
import { Getter } from 'vuex-class';
import moment from 'moment';

@Component({
  components: {
    SwecoInput,
    SwecoSelect,
    SwecoCheckbox,
    SwecoRadioButton,
    SwecoDatePicker,
    SwecoImageRadioButton,
    SwecoImageCheckbox,
  },
  validations: {},
})
export default class SwecoForm extends Vue {
  @Prop({ default: null }) form!: Form;
  disabled: boolean = false;
  validationError: string = '';
  formErrors: validation[] = [];
  anyErrors: boolean = false;

  @Getter('getActiveItem')
  activeItem!: ActiveItem;

  getChecked(field: Field, value: any, container: Container) {
    if (field.defaultValue == value.id && field.defaultValue !== null && field.value === null) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < container.fields.length; i++) {
        if (field.type.controlType.key === 'CheckBoxList' || field.type.controlType.key === 'RadioButtonList') {
          // tslint:disable-next-line:prefer-for-of
          for (let k = 0; k < container.fields.length; k++) {
            if (container.fields[k].dependsOn && container.fields[k].dependsOn.equalValue === value.id) {
              for (const v of container.fields[i].type.values) {
                this.$set(v, 'selected', false);
              }
              this.$set(container.fields[k], 'show', true);
            }
          }
        }
      }
      return true;
    }
  }

  created() {
    MessageBus.$on('validate', this.onValidate);
    MessageBus.$on('resetValidation', this.onValidate);
  }

  beforeDestroy() {
    MessageBus.$off('validate', this.onValidate);
    MessageBus.$off('resetValidation', this.onValidate);
  }

  private onValidate() {
    this.validateForm();
  }

  get getFormErrors() {
    return this.formErrors;
  }

  ishorizontalList = (field: Container) => {
    return field.horizontalList;
  };

  @Watch('form', { immediate: true })
  preProcessForm() {
    const listOfDepends = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.form.containers.length; i++) {
      this.formErrors.push(new validation());
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.form.containers[i].fields.length; j++) {
        const field = this.form.containers[i].fields[j];
        if (field.dependsOn) {
          listOfDepends.push(field);
        } else {
          field.show = true;
        }
      }
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < listOfDepends.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.form.containers.length; j++) {
        // tslint:disable-next-line:prefer-for-of
        for (let k = 0; k < this.form.containers[j].fields.length; k++) {
          if (listOfDepends[i].dependsOn.id == this.form.containers[j].fields[k].id) {
            const equalValue = listOfDepends[i].dependsOn.equalValue;
            const value = this.form.containers[j].fields[k].value;
            if (this.form.containers[j].fields[k].type.controlType.key == 'CheckBox') {
              const boolValue = listOfDepends[i].dependsOn.equalValue === 'True';
              if (equalValue && boolValue.toString() == value) {
                this.$set(listOfDepends[i], 'show', true);
              } else {
                this.$set(listOfDepends[i], 'show', false);
              }
            } else {
              if (equalValue && equalValue == value) {
                this.$set(listOfDepends[i], 'show', true);
              } else {
                this.$set(listOfDepends[i], 'show', false);
              }
            }
          }
        }
      }
    }
  }

  setDependencyVisibility(field: Field, container: Container) {
    const containerIndex = this.form.containers.indexOf(container);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < container.fields.length; i++) {
      if (field.type.controlType.key == 'CheckBoxList') {
        this.$set(field, 'value', '');
        let update = '';
        for (const value of field.type.values) {
          if (value.selected) {
            update = update == '' ? value.id : update + ', ' + value.id;
          }
        }
        this.$set(field, 'value', update);
      }
      const dependsOn = container.fields[i].dependsOn;
      if (dependsOn && dependsOn.id == field.id) {
        const equalValue = container.fields[i].dependsOn.equalValue;
        if (field.type.controlType.key == 'CheckBox') {
          const boolValue = equalValue === 'True';
          if (equalValue && boolValue.toString() == field.value) {
            this.$set(container.fields[i], 'show', true);
          } else {
            this.$set(container.fields[i], 'show', false);
          }
        } else if (field.type.controlType.key == 'CheckBoxList' && field.value.includes(equalValue)) {
          this.$set(container.fields[i], 'show', true);
        } else if (equalValue && equalValue == field.value) {
          this.$set(container.fields[i], 'show', true);
        } else {
          if (field.type.controlType.key === 'CheckBoxList' || field.type.controlType.key === 'RadioButtonList') {
            // tslint:disable-next-line:prefer-for-of
            for (let k = 0; k < container.fields.length; k++) {
              if (container.fields[k].dependsOn && container.fields[k].dependsOn.id === container.fields[i].id) {
                for (const v of container.fields[i].type.values) {
                  this.$set(v, 'selected', false);
                }
                this.$set(container.fields[k], 'show', false);
              }
            }
          }
          this.$set(container.fields[i], 'show', false);
        }
      }
    }
    this.validateField(field, containerIndex);
  }

  validateField(field: Field, containerIndex: number) {
    if (!field.value && field.required) {
      this.formErrors[containerIndex].error = true;
      this.formErrors[containerIndex].text = this.$t.citizenTranslations.citizenDetailsErrorRequired;
      this.anyErrors = true;
    } else {
      this.formErrors[containerIndex].error = false;
      this.formErrors[containerIndex].text = '';
    }
  }

  validateForm() {
    let errorDetected: boolean = false;
    this.activeItem.formFieldValues.fieldValues = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.form.containers.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.form.containers[i].fields.length; j++) {
        const field = this.form.containers[i].fields[j];
        if (field.value) {
          if (field.dependsOn) {
            const index = this.activeItem.formFieldValues.fieldValues.findIndex(item => item.fieldId == field.dependsOn.id);
            if (index !== -1) {
              this.activeItem.formFieldValues.fieldValues[index].show = false;
            }
          }
          const fieldValue = new FieldValue();
          fieldValue.fieldId = field.id;
          fieldValue.value = field.value;
          fieldValue.label = field.label;
          fieldValue.show = true;
          if (field.type.controlType.key === 'Date') {
            fieldValue.valueStrings.push(moment(field.value).format('DD-MM-YYYY HH:mm'));
          } else if (field.type.controlType.key === 'Text' || field.type.controlType.key === 'Number') {
            fieldValue.valueStrings.push(field.value);
          } else {
            for (const value of field.type.values) {
              if (fieldValue.value.includes(value.id)) {
                fieldValue.valueStrings.push(value.name);
              }
            }
          }
          this.activeItem.formFieldValues.fieldValues.push(fieldValue);
        }

        if (field.dependsOn) {
          const index = this.activeItem.formFieldValues.fieldValues.findIndex(item => item.fieldId == field.dependsOn.id);
          if (index === -1 || this.activeItem.formFieldValues.fieldValues[index].value != field.dependsOn.equalValue) {
            this.formErrors[i].error = false;
            this.formErrors[i].text = '';
          }
        } else if (!field.value && field.required) {
          this.formErrors[i].error = true;
          this.formErrors[i].text = this.$t.citizenTranslations.citizenDetailsErrorRequired;
          this.anyErrors = true;
          errorDetected = true;
        } else {
          this.formErrors[i].error = false;
          this.formErrors[i].text = '';
        }
      }
    }
    if (!errorDetected) {
      this.anyErrors = false;
    }
  }
}
