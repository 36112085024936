import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import moment from 'moment';
import { HttpResponse } from '@/networking/interfaces';
import CitizenConfig from '@/models/citizenConfig';
import ActiveItem, { Attachments, Comment, FormFieldValues } from '@/models/activeItem';
import httpClient from '@/networking/httpclient';
import CategoryGroupResponse from '@/models/categoryGroupResponse';
import Issue from '@/models/issue';
import Form from '@/models/form';
import Mapping from '@/store/mapping';
import { texts } from '@/texts';
import Attachment from '@/models/attachment';
import { State } from 'vuex-class';

Vue.use(Vuex);
const mapping = new Mapping();

export default new Vuex.Store({
  state: {
    citizenConfig: new CitizenConfig(),
    activeItem: new ActiveItem(),
    attachments: new Attachments(),
    categoryGroups: new CategoryGroupResponse(),
    form: new Form(),
  },
  mutations: {
    SET_CONFIG(state, citizenConfig) {
      state.citizenConfig = citizenConfig;
    },
    SET_CATEGORYGROUPS(state, categoryGroups) {
      state.categoryGroups = categoryGroups;
    },
    SET_ACTIVEITEM(state, data) {
      state.activeItem = mapping.issueToActiveItem(data);
    },
  },
  actions: {
    async FETCH_CONFIG({ commit }, citizenId: string): Promise<HttpResponse> {
      const { status, data } = await httpClient.get('citizen/citizenconfiguration/' + citizenId, {
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
      });
      texts.citizenTranslations = data.translations;
      texts.phoneRegex = data.regexValidationPhone;
      localStorage.lang = data.lang;
      commit('SET_CONFIG', data);
      return { status, data };
    },
    async FETCH_CATEGORYGROUPS({ commit }, citizenId: string): Promise<HttpResponse> {
      const { status, data } = await httpClient.get('citizen/citizencategories/' + citizenId);
      commit('SET_CATEGORYGROUPS', data);
      return { status, data };
    },
    async FETCH_ISSUE({ commit }, { issueId, citizenId }): Promise<HttpResponse> {
      const { status, data } = await httpClient.get('citizen/citizenissue?id=' + issueId + '&moduleConfigurationId=' + citizenId);

      commit('SET_ACTIVEITEM', data);

      return { status, data };
    },
    async FETCH_MAP_ISSUE({ commit }, { issueId, citizenId }): Promise<HttpResponse> {
      const { status, data } = await httpClient.get('citizen/citizenissue?id=' + issueId + '&moduleConfigurationId=' + citizenId);

      return { status, data };
    },
    async SAVE_ISSUE({ commit }, issue: Issue): Promise<any> {
      return new Promise((resolve, reject) => {
        try {
          httpClient
            .post('issues/', issue)
            .then(response => {
              if (response && !response.data.error) {
                resolve({ success: true, data: response.data });
              } else {
                resolve({ success: false, error: response.data.error });
              }
            })
            .catch(response => {
              resolve(false);
            });
        } catch (error) {
          resolve(false);
        }
      });
    },
    async SAVE_FORM_FIELD_VALUES({ commit }, formFieldValues: FormFieldValues): Promise<any> {
      return new Promise((resolve, reject) => {
        try {
          httpClient
            .post('citizen/citizenform/', formFieldValues)
            .then(response => {
              if (response && !response.data.error) {
                resolve({ success: true, data: response.data });
              } else {
                resolve({ success: false, error: response.data.error });
              }
            })
            .catch(response => {
              resolve(false);
            });
        } catch (error) {
          resolve(false);
        }
      });
    },
  },
  modules: {},
  getters: {
    getMapSearchSettings: state => {
      return state.citizenConfig.mapSearchSettings;
    },
    getConfig: state => {
      return state.citizenConfig;
    },
    getActiveItem: state => {
      if (sessionStorage.email) {
        state.activeItem.reporterEmail.value = sessionStorage.email;
      }
      if (sessionStorage.name) {
        state.activeItem.reporterName.value = sessionStorage.name;
      }
      if (sessionStorage.phone) {
        state.activeItem.reporterPhone.value = sessionStorage.phone;
      }
      if (sessionStorage.notify === 'yes') {
        state.citizenConfig.enableMailNotification = true;
        state.citizenConfig.mailNotificationPreselected = true;
        state.activeItem.notificationEmail = true;
      }

      return state.activeItem;
    },
    getAttachments: state => {
      return state.attachments;
    },
    getCategoryGroups: state => {
      return state.categoryGroups;
    },
    getForm: state => {
      return state.form;
    },
  },
});
