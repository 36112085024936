
































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SwecoInput from '@/components/input/SwecoInput.vue';
import SwecoSelect from '@/components/input/SwecoSelect.vue';
import SwecoTextarea from '@/components/input/SwecoTextarea.vue';
import SwecoModal from '@/components/input/SwecoModal.vue';
import SwecoForm from '@/components/input/SwecoForm.vue';
import SwecoDatePicker from '@/components/input/SwecoDatePicker.vue';
import DawaAddressSearch from '@/components/input/DawaAddressSearch.vue';
import LocalAddressSearch from '@/components/input/LocalAddressSearch.vue';
import SwecoElementSelect from '@/components/input/SwecoElementSelect.vue';
import StatusBar from '@/components/StatusBar.vue';
import SwecoUpload from '@/components/input/SwecoUpload.vue';
import { Getter } from 'vuex-class';
import ActiveItem, { ReporterPhone, ReporterEmail, ReporterName, ReporterAddress, Description, ObservationTime } from '@/models/activeItem';
import Form from '@/models/form';
import Config, { Tab } from '@/models/citizenConfig';
import httpClient from '@/networking/httpclient';
import { required, requiredIf, email } from 'vuelidate/lib/validators';

@Component({
  components: {
    SwecoInput,
    DawaAddressSearch,
    SwecoSelect,
    SwecoTextarea,
    SwecoModal,
    SwecoForm,
    SwecoDatePicker,
    SwecoUpload,
    LocalAddressSearch,
    StatusBar,
    SwecoElementSelect,
  },
  validations: {
    activeItem: {
      addressObject: {
        inputLocationNear: {
          required,
        },
      },
      observationTime: {
        value: {
          required: requiredIf(function (this: ObservationTime, instance) {
            return instance.required;
          }),
        },
      },
      reporterName: {
        value: {
          required: requiredIf(function (this: ReporterName, instance) {
            return instance.required;
          }),
        },
      },
      reporterAddress: {
        value: {
          required: requiredIf(function (this: ReporterAddress, instance) {
            return instance.required;
          }),
        },
      },
      reporterEmail: {
        value: {
          required: requiredIf(function (this: ReporterEmail, instance) {
            return instance.required;
          }),
          email,
        },
      },
      reporterPhone: {
        value: {
          required: requiredIf(function (this: ReporterPhone, instance) {
            return instance.required;
          }),
        },
      },
      description: {
        value: {
          required: requiredIf(function (this: Description, instance) {
            return instance.required;
          }),
        },
      },
      category: {
        required,
      },
    },
  },
})
export default class Step1 extends Vue {
  showSplash: boolean = false;
  splashText: string = '';
  localAddressUrl: string = '';
  srid: number = 0;
  area: string = '';
  mapAlert: boolean = false;

  filesLoading: boolean = false;

  form: Form | null = null;
  formResponseText: string = '';

  getIssueShield(statusId: string) {
    return '../../../../src/assets/shields/' + statusId + '.svg';
  }

  @Prop() tab!: Tab;
  @Prop() currentTab!: number;

  //@ts-ignore
  mapSrc: string = MAP_URL + '/' + localStorage.citizenId;

  @Getter('getActiveItem')
  activeItem!: ActiveItem;

  @Getter('getConfig')
  config!: Config;

  @Watch('config', { immediate: true })
  reloadActiveItemState(nextValue: Config) {
    this.activeItem.reporterName.required = nextValue.requiredName;
    this.activeItem.reporterName.enabled = nextValue.enableName;

    this.activeItem.reporterAddress.required = nextValue.requiredAddress;
    this.activeItem.reporterAddress.enabled = nextValue.enableAddress;

    this.activeItem.reporterPhone.required = nextValue.requiredPhone;
    this.activeItem.reporterPhone.enabled = nextValue.enablePhone;

    this.activeItem.reporterEmail.required = nextValue.requiredEmail;
    this.activeItem.reporterEmail.enabled = nextValue.enableEmail;

    this.activeItem.observationTime.required = nextValue.requiredObservationTime;
    this.activeItem.observationTime.enabled = nextValue.enableObservationTime;

    this.activeItem.description.required = nextValue.requiredComments;
    this.activeItem.description.enabled = nextValue.enableComments;

    this.activeItem.attachments.required = nextValue.requiredAttachments;

    this.activeItem.notificationEmail = nextValue.mailNotificationPreselected;
    this.activeItem.notificationSms = nextValue.smsNotificationPreselected;

    if (nextValue.splashText && nextValue.splashText != '') {
      this.showSplash = true;
      this.splashText = nextValue.splashText;
    }

    if (
      this.config.mapSearchSettings.geoSearchServices[0].serviceKey.toLowerCase() === 'local' ||
      this.config.mapSearchSettings.geoSearchServices[0].serviceKey.toLowerCase() === 'kartverket' ||
      this.config.mapSearchSettings.geoSearchServices[0].serviceKey.toLowerCase() === 'lantmateriet'
    ) {
      this.area = this.config.mapSearchSettings.geoSearchServices[0].area;
      this.srid = this.config.mapSearchSettings.srid;
      this.localAddressUrl = this.config.mapSearchSettings.geoSearchServices[0].baseUrl;
    }
  }

  get isDawa() {
    return (
      this.config.mapSearchSettings.geoSearchServices[0].serviceKey.toLowerCase() !== 'local' &&
      this.config.mapSearchSettings.geoSearchServices[0].serviceKey.toLowerCase() !== 'kartverket' &&
      this.config.mapSearchSettings.geoSearchServices[0].serviceKey.toLowerCase() !== 'lantmateriet'
    );
  }

  get formAvailable() {
    return this.form != null;
  }

  @Watch('activeItem.category')
  getForm() {
    if (this.activeItem && this.activeItem.category && this.activeItem.category.id) {
      httpClient
        .get('citizen/citizenform?categoryId=' + this.activeItem.category.id + '&clientId=' + this.config.clientId)
        .then(response => {
          if (response && response.data) {
            this.form = response.data;
          } else {
            this.form = null;
          }
        })
        .catch(response => {
          this.formResponseText = 'Kunne ikke hente formular';
        });
    }
  }

  get addressValue() {
    return this.activeItem.addressObject.inputLocationNear != null ? this.activeItem.addressObject.inputLocationNear : '';
  }

  set addressValue(value) {
    this.activeItem.addressObject.inputLocationNear = value;
  }

  mounted() {
    setTimeout(() => {
      if (this.config.enableName) {
        (this.$refs.reporterName as SwecoInput).setDisableValidation(!this.activeItem.reporterName.required);
      }
      if (this.config.enableAddress) {
        (this.$refs.reporterAddress as SwecoInput).setDisableValidation(!this.activeItem.reporterAddress.required);
      }
      if (this.config.enablePhone) {
        (this.$refs.reporterPhone as SwecoInput).setDisableValidation(!this.activeItem.reporterPhone.required);
      }
      if (this.config.enableEmail) {
        (this.$refs.reporterEmail as SwecoInput).setDisableValidation(!this.activeItem.reporterEmail.required);
      }
      if (this.config.enableComments) {
        (this.$refs.description as SwecoTextarea).setDisableValidation(!this.activeItem.description.required);
      }
    }, 1000);
  }

  @Watch('currentTab')
  currentTabChanged() {
    if (this.currentTab === 1) {
      setTimeout(() => {
        if (this.config.enableName) {
          (this.$refs.reporterName as SwecoInput).setDisableValidation(!this.activeItem.reporterName.required);
        }

        if (this.config.enableAddress) {
          (this.$refs.reporterAddress as SwecoInput).setDisableValidation(!this.activeItem.reporterAddress.required);
        }

        if (this.config.enablePhone) {
          (this.$refs.reporterPhone as SwecoInput).setDisableValidation(!this.activeItem.reporterPhone.required);
        }

        if (this.config.enableEmail) {
          (this.$refs.reporterEmail as SwecoInput).setDisableValidation(!this.activeItem.reporterEmail.required);
        }

        if (this.config.enableComments) {
          (this.$refs.description as SwecoTextarea).setDisableValidation(!this.activeItem.description.required);
        }
      }, 1000);
    }
  }

  onSelected(data: any) {
    if (this.config && !this.config.disableMap) {
      let srid = '';

      if (data.representasjonspunkt) {
        srid = data.representasjonspunkt.epsg.replace('EPSG:', '');
      }

      this.$nextTick(() => {
        //@ts-ignore
        this.mapSrc = MAP_URL + '/' + localStorage.citizenId;

        if (this.isDawa) {
          //@ts-ignore
          this.mapSrc +=
            '?wkt=POINT(' + data.data.x + ' ' + data.data.y + ')&srid=' + srid + '&step=' + (this.tab.sortorder + 1) + '&setPosition=false';
        } else {
          //@ts-ignore
          this.mapSrc +=
            '?wkt=' +
            data.wkt +
            '&srid=' +
            srid +
            '&step=' +
            (this.tab.sortorder + 1) +
            '&addressServiceKey=' +
            this.config.mapSearchSettings.geoSearchServices[0].serviceKey.toLowerCase() +
            '&setPosition=false';
        }

        debugger;
      });
    } else {
      //@ts-ignore
      const url = MAP_URL + '/' + localStorage.citizenId + '/GetNearestAddress?wkt=POINT(' + data.data.x + ' ' + data.data.y + ')';

      httpClient
        .get(url)
        .then(response => {
          const result = {
            event: 'address',
            address: response.data,
          };

          const json = JSON.stringify(result);
          window.parent.postMessage(json, '*');
        })
        .catch(response => {
          console.error('Failed to make request to ' + url);
        });
    }
  }
}
