




















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { required, minLength, numeric, maxLength, helpers, requiredIf, email } from 'vuelidate/lib/validators';
import SwecoSelect from '@/components/input/SwecoSelect.vue';
import CategoryGroupResponse, { CategoryGroup, ElementType, Category } from '@/models/categoryGroupResponse';
import ActiveItem from '@/models/activeItem';
@Component({
  components: {
    SwecoSelect,
  },
  validations: {
    activeItem: {
      category: {
        required,
      },
    },
  },
})
export default class SwecoElementSelect extends Vue {
  @Prop({ default: 2 }) elementChooser!: number;
  @Prop({ default: '' }) categoryGroupId!: string;

  selectedCategoryGroup: CategoryGroup = new CategoryGroup();
  moreThanOneCategoryGroup: boolean = false;
  selectedElementType: ElementType = new ElementType();
  moreThanOneElementType: boolean = false;

  @Getter('getCategoryGroups')
  categoryGroupResponse!: CategoryGroupResponse;

  @Getter('getActiveItem')
  activeItem!: ActiveItem;

  @Watch('categoryGroupResponse', { immediate: true })
  categoryGroupCount() {
    switch (this.elementChooser) {
      case 0:
        if (this.categoryGroupResponse.categoryGroupSeparated.length === 1) {
          this.selectedCategoryGroup = this.categoryGroupResponse.categoryGroupSeparated[0];
          this.moreThanOneCategoryGroup = false;
        } else {
          this.selectedCategoryGroup = new CategoryGroup();
          this.moreThanOneCategoryGroup = true;
          if (this.categoryGroupId != '') {
            for (const categoryGroup in this.categoryGroupResponse.categoryGroupSeparated) {
              if (Object.prototype.hasOwnProperty.call(this.categoryGroupResponse.categoryGroupSeparated, categoryGroup)) {
                const element = this.categoryGroupResponse.categoryGroupSeparated[categoryGroup];
                if (element.id === this.categoryGroupId) {
                  this.selectedCategoryGroup = element;
                }
              }
            }
          }
        }
        break;
      case 1:
        if (this.categoryGroupResponse.categoryGroupElementTypeCombined.length === 1) {
          this.selectedCategoryGroup = this.categoryGroupResponse.categoryGroupElementTypeCombined[0];
          this.moreThanOneCategoryGroup = false;
        } else {
          this.selectedCategoryGroup = new CategoryGroup();
          this.moreThanOneCategoryGroup = true;

          if (this.categoryGroupId != '') {
            for (const categoryGroup in this.categoryGroupResponse.categoryGroupElementTypeCombined) {
              if (Object.prototype.hasOwnProperty.call(this.categoryGroupResponse.categoryGroupElementTypeCombined, categoryGroup)) {
                const element = this.categoryGroupResponse.categoryGroupElementTypeCombined[categoryGroup];
                if (element.id === this.categoryGroupId) {
                  this.selectedCategoryGroup = element;
                }
              }
            }
          }
        }
        break;
    }

    this.elementTypesCount();
  }
  @Watch('selectedCategoryGroup.elementTypes')
  elementTypesCount() {
    if (this.elementChooser === 0) {
      if (this.selectedCategoryGroup.elementTypes.length === 1) {
        this.selectedElementType = this.selectedCategoryGroup.elementTypes[0];
        this.moreThanOneElementType = false;
      } else {
        this.selectedElementType = new ElementType();
        this.moreThanOneElementType = true;
      }
    }
  }

  get getCategoryGroupsSeparated() {
    return this.categoryGroupResponse.categoryGroupSeparated;
  }

  get getCategoryGroupsElementTypeCombined() {
    return this.categoryGroupResponse.categoryGroupElementTypeCombined;
  }

  get getCategoryGroupsCombined() {
    return this.categoryGroupResponse.categoryGroupCombined;
  }
}
