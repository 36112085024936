




























import Vue from 'vue';
import { Guid } from 'guid-typescript';
import { dawaAutocomplete } from 'dawa-autocomplete2';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { MessageBus } from '@/components/MessageBus';
import { Getter } from 'vuex-class';
import { Address } from '@/models/addressObject';
import ActiveItem from '@/models/activeItem';
import { MapSearchSettings } from '@/models/citizenConfig';

@Component
export default class DawaAddressSearch extends Vue {
  id: string = 'dawa-autocomplete-input' + Guid.create().toString();
  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: null }) address!: Address;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: null }) validations!: vuelidate.Params;

  @Getter('getMapSearchSettings')
  mapSearchSettings!: MapSearchSettings;
  validationError: string = '';
  component: any = null;
  localUpdate: boolean = false;
  area!: string;

  @Getter('getActiveItem')
  activeItem!: ActiveItem;

  @Watch('area', { immediate: true })
  areaChange() {
    if (this.mapSearchSettings) {
      this.$nextTick(() => {
        this.initDawaSearch();
      });
    }
  }

  @Watch('activeItem.addressObject.locationNear')
  setLocationNear() {
    this.setInput(this.activeItem.addressObject.locationNear);
  }

  @Watch('activeItem.addressObject.altLocationNear')
  setAltLocationNear() {
    this.setInput(this.activeItem.addressObject.altLocationNear);
  }
  setInput(locationNear: string) {
    if (locationNear) {
      this.$emit('update:value', locationNear);
    }
    this.validateInput();
  }

  validateScrollTo() {
    if (this.validationError) {
      setTimeout(() => {
        (this.$refs.inputField as any).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 10);
    }
  }

  initDawaSearch() {
    const inputElm = document.getElementById(this.id) as HTMLInputElement;
    inputElm.value = '';
    if (this.mapSearchSettings.geoSearchServices[0].area && this.mapSearchSettings.geoSearchServices[0].area != '') {
      this.component = (dawaAutocomplete as any)(inputElm, {
        id: this.value ? this.value : '', //DAWA Adgangsadresse ID. (Dette gør så den automatisk har en adresse initaliseret)
        select: (result: any) => {
          this.setAddressAndValue(result);
          this.$emit('selected', result);
        },
        minLength: 2,
        type: 'adgangsadresse',
        params: { kommunekode: this.convertAreas(), srid: this.mapSearchSettings.srid },
      });
    } else {
      this.component = (dawaAutocomplete as any)(inputElm, {
        id: this.value ? this.value : '', //DAWA Adgangsadresse ID. (Dette gør så den automatisk har en adresse initaliseret)
        select: (result: any) => {
          this.setAddressAndValue(result);
          this.$emit('selected', result);
        },
        minLength: 2,
        type: 'adgangsadresse',
        params: { srid: this.mapSearchSettings.srid },
      });
    }
  }

  convertAreas() {
    const area = this.mapSearchSettings.geoSearchServices[0].area;
    return area == '' ? '' : area.replace('muncode', '');
  }

  onChange(e: any) {
    if (e.currentTarget.value === '') {
      this.$emit('update:value', '');

      const map = this.$parent.$refs.map as any;
      const msg = '{"event": "noAddress"}';
      map.contentWindow.postMessage(msg, '*');
    }
  }

  setAddressAndValue(result: any) {
    if (result && result.data) {
      this.$emit('update:value', result.tekst);
    }
    this.validateInput();
  }

  created() {
    MessageBus.$on('validate', () => {
      this.validateInput();
      this.validateScrollTo();
    });
    MessageBus.$on('resetValidation', () => {
      this.validationError = '';
      const inputField = this.$refs.inputField as HTMLElement;
      if (inputField != null) {
        inputField.setAttribute('aria-invalid', 'false');
      }
    });
  }

  get isRequired() {
    const isFieldRequired =
      this.validations != null &&
      this.validations.$params != null &&
      this.validations.$params.required &&
      this.validations.$params.required.type === 'required';

    return isFieldRequired;
  }

  get requireText() {
    return this.isRequired
      ? '* <span style="font-size: 10px;">(' + this.$t.citizenTranslations.citizenDetailsErrorRequired + ')</span>'
      : '';
  }

  validateInput() {
    this.validationError = '';
    const inputField = this.$refs.inputField as HTMLElement;
    if (inputField != null) {
      inputField.setAttribute('aria-invalid', 'false');
    }

    if (this.validations) {
      if (!this.validationError && this.validations.required === false) {
        this.validationError = this.$t.citizenTranslations.citizenDetailsErrorRequired;
        if (inputField != null) {
          inputField.setAttribute('aria-invalid', 'true');
        }
      }
    }

    if (!this.validationError && this.activeItem && !this.activeItem.addressObject.valid) {
      this.validationError = this.$t.citizenTranslations.citizenDetailsSetPointInMapHelp;
      if (inputField != null) {
        inputField.setAttribute('aria-invalid', 'true');
      }
    }
  }
}
