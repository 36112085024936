import Vue from 'vue';
import { Translations } from '@/models/citizenConfig';

declare module 'vue/types/vue' {
  interface Vue {
    $t: Texts;
  }
}

export class Texts {
  phoneRegex: string = '';
  emailRegex: string =
    '^[-!#$%&"*+/0-9=?A-Z^_a-z{|}~](.?[-!#$%&"*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z0-9](-?[a-zA-Z0-9])*(.[a-zA-Z0-9](-?[a-zA-Z0-9])*)+$';
  citizenTranslations: Translations = new Translations();
}

export const texts = new Texts();
