





















import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { HttpResponse } from '@/networking/interfaces';
import SwecoModal from '@/components/input/SwecoModal.vue';
import ActiveItem from '@/models/activeItem';

@Component({
  components: {
    SwecoModal,
  },
})
export default class CitizenLink extends Vue {
  @Prop() show!: boolean;

  @Getter('getActiveItem')
  activeItem!: ActiveItem;

  @Watch('show')
  setShow() {
    this.showDialog = this.show;
  }
  showDialog: boolean = false;

  setProp(newValue: boolean) {
    this.showDialog = newValue;
    this.$emit('show', this.showDialog);
    this.$emit('update:show', this.showDialog);
  }
}
