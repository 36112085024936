import axios from 'axios';
import store from '@/store';
import router from '@/router';

import { HttpOptions, HttpCancelToken, HttpResponse, HttpFileResponse } from './interfaces';
import { MessageBus } from '@/components/MessageBus';

// TODO: Shouldn't export the base URL as all interaction with the API must happen through the http abstraction layer.
//@ts-ignore The variable API_URL is defined in vue.config.js
export let BASE_API_URL = API_URL;
//@ts-ignore
export let BASE_URL = DOMAIN_URL;

const standardClient = axios.create({
  baseURL: BASE_API_URL
});

standardClient.interceptors.request.use(
  config => {
    config.headers.common['citizentoken'] = localStorage.citizenId;
    config.headers.common['Content-Type'] = 'application/json';

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

standardClient.interceptors.response.use(
  response => {
    if (response.config.responseType === 'arraybuffer' || response.config.responseType === 'blob') {
      return Promise.resolve(response);
    }
    const retval = JSON.parse(JSON.stringify(response.data));
    response.data = retval;
    return Promise.resolve(response);
  },
  error => {
    // unauthorized
    if (error && !axios.isCancel(error)) {
      MessageBus.$emit('httpError', error.response);
    }

    return Promise.reject(error);
  }
);

function newHttpOptions(): HttpOptions {
  return {
    cancelToken: new HttpCancelToken()
  };
}

export async function get(url: string, options: HttpOptions = newHttpOptions()): Promise<HttpResponse> {
  const { status, data } =
    options.cancelToken != null
      ? await standardClient.get(url, { cancelToken: options.cancelToken.source.token })
      : await standardClient.get(url);
  return { status, data };
}

export async function post(url: string, payload: any, options: HttpOptions = newHttpOptions()): Promise<HttpResponse> {
  const { status, data } =
    options.cancelToken != null
      ? await standardClient.post(url, payload, { cancelToken: options.cancelToken.source.token })
      : await standardClient.post(url, payload);
  return { status, data };
}

export async function put(url: string, payload: any, options: HttpOptions = newHttpOptions()): Promise<HttpResponse> {
  const { status, data } =
    options.cancelToken != null
      ? await standardClient.put(url, payload, { cancelToken: options.cancelToken.source.token })
      : await standardClient.put(url, payload);
  return { status, data };
}

export async function del(url: string, options: HttpOptions = newHttpOptions()): Promise<HttpResponse> {
  const { status, data } =
    options.cancelToken != null
      ? await standardClient.delete(url, { cancelToken: options.cancelToken.source.token })
      : await standardClient.delete(url);
  return { status, data };
}

// export async function download(url: string, options: HttpOptions = newHttpOptions()): Promise<HttpFileResponse> {
//   const config = {
//     responseType: 'arraybuffer',
//     headers: {
//       Accept: 'application/octet-stream'
//     }
//   };

//   const { status, data, headers } =
//     options.cancelToken != null
//       ? await standardClient.get(url, { ...config, cancelToken: options.cancelToken.source.token })
//       : await standardClient.get(url, config);

//   const filename = parseFilenameFromContentDisposition(headers['content-disposition']);
//   const mimeType = headers['content-type'];

//   return { status, data, filename, mimeType };
// }

export default standardClient;
