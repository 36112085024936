
































import { Prop, Vue, Component, Watch } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { MessageBus } from '@/components/MessageBus';
import { Guid } from 'guid-typescript';

@Component
export default class SwecoInput extends Vue {
  @Prop({ default: '' }) text!: any;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) focus!: boolean;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: 'text' }) type!: string;
  @Prop({ default: '' }) step!: string;
  @Prop() min!: number;
  @Prop() max!: number;
  @Prop({ default: null }) validations!: vuelidate.Params;
  @Prop({ default: '' }) regex!: string;
  @Prop({ default: '' }) regexType!: string;
  @Prop({ default: false }) required!: boolean;

  tempId: string = 'input' + Guid.create().toString();
  validationError: string = '';
  scrollingAnimationActive: boolean = false;
  originalText: string = '';
  disableValidation: boolean = false;

  setFocus() {
    const inputField = this.$refs.inputField as HTMLElement;

    if (inputField) {
      inputField.focus();
    }
  }

  validateScrollTo() {
    if (this.validations && this.validations.$invalid) {
      setTimeout(() => {
        (this.$refs.inputField as any).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 10);
    }
  }

  created() {
    if (typeof this.text === 'string') {
      this.originalText = this.text.slice();
    } else if (typeof this.text === 'number') {
      this.originalText = this.text.toString();
    }

    MessageBus.$on('validate', () => {
      this.validateInput();
      this.validateScrollTo();
    });
    MessageBus.$on('resetValidation', () => {
      this.validationError = '';
      const inputField = this.$refs.inputField as HTMLElement;
      if (inputField != null) {
        inputField.setAttribute('aria-invalid', 'false');
      }
    });
  }

  blur() {
    this.$emit('blur');
    this.validateInput();
  }

  validateInput() {
    this.validationError = '';
    const inputField = this.$refs.inputField as HTMLElement;
    if (inputField != null) {
      inputField.setAttribute('aria-invalid', 'false');
    }

    if (this.validations && !this.disableValidation) {
      if (!this.validationError && this.validations.required === false) {
        this.validationError = this.$t.citizenTranslations.citizenDetailsErrorRequired;
        if (inputField != null) {
          inputField.setAttribute('aria-invalid', 'true');
        }
      }
    }

    if (this.regex && this.regex != '' && this.text != '') {
      let r = this.regex;
      let n = '';
      if (this.regex.charAt(0) == '/') {
        n = this.regex.substring(1);
        r = n;
      }
      if (this.regex.charAt(r.length) == '/') {
        n = r.slice(0, -1);
        r = n;
      }

      if (!new RegExp(r).test(this.text)) {
        if (this.regexType == 'phone') {
          this.validationError = this.$t.citizenTranslations.citizenDetailsErrorPhone;
          if (inputField != null) {
            inputField.setAttribute('aria-invalid', 'true');
          }
        } else if (this.regexType == 'email') {
          this.validationError = this.$t.citizenTranslations.citizenDetailsErrorEmail;
          if (inputField != null) {
            inputField.setAttribute('aria-invalid', 'true');
          }
        } else {
          this.validationError = this.$t.citizenTranslations.citizenDetailsCommenRegexError;
          if (inputField != null) {
            inputField.setAttribute('aria-invalid', 'true');
          }
        }
      }
    }
  }

  get currentMax() {
    return 350;
  }
  get currentLength() {
    return this.text ? this.text.length : 0;
  }

  get isRequired() {
    if (this.required) {
      return this.required;
    } else {
      return this.validations != null && this.validations.$params != null && this.validations.$params.required;
    }
  }

  get requireText() {
    return this.isRequired && !this.disableValidation
      ? '* <span style="font-size: 10px;">(' + this.$t.citizenTranslations.citizenDetailsErrorRequired + ')</span>'
      : '';
  }

  public setDisableValidation(disable: boolean) {
    //Hvis vi ønsker at fjerne valideringen (og dermed stjernen) på baggrund af et andet input, er det tilsyneladende ikke muligt at fjerne en validation fra opsætningen når vi bruger typescript
    //i opsætningen kan man ikke tilgå komponent instansen, og i komponent instancen er der ikke nogen officielt vuelidate måde at tilføje/fjerne en validering. Det er måske muligt med et grimt hack
    //men så hellere denne løsning.
    this.disableValidation = disable;
  }

  input(event: any) {
    if (this.regexType == 'email' || this.regexType == 'phone') {
      event.target.value = event.target.value.trim();
    }
    this.$emit('update:text', event.target.value);
    if (this.validationError) {
      this.validateInput();
    }
  }
}
