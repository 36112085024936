



























































import { Component, Vue, Watch } from 'vue-property-decorator';
import Wizard from '@/components/wizard/Wizard.vue';
import { MessageBus } from '@/components/MessageBus';
import CitizenConfig from '@/models/citizenConfig';
import { Getter } from 'vuex-class';
//import 'vue-select/dist/vue-select.css';

@Component({
  components: {
    Wizard,
  },
})
export default class App extends Vue {
  currentTab: number = 1;
  showButtons = false;
  rightButtonText: string = this.$t.citizenTranslations.citizenDetailsNext;
  loading: boolean = false;

  @Getter('getConfig')
  config!: CitizenConfig;

  mounted() {
    this.showButtons = localStorage.citizenType === 'Wizard';
  }

  @Watch('config')
  updateActiveItem() {
    const html = document.documentElement;
    html.setAttribute('lang', this.config.lang);
  }

  created() {
    document.title = 'DriftWeb Citizen';

    MessageBus.$on('rightButtonText', (e: string) => {
      this.rightButtonText = e;
    });
    MessageBus.$on('step', (e: number) => {
      this.currentTab = e;
    });
  }

  lastStep() {
    MessageBus.$emit('lastStep');
  }

  nextStep() {
    MessageBus.$emit('nextStep');
  }
}
