




























































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { HttpResponse } from '@/networking/interfaces';
import SwecoModal from '@/components/input/SwecoModal.vue';
import { LookupIssue } from '@/models/lookupItem';
import Issue from '@/models/issue';

@Component({
  components: {
    SwecoModal,
  },
})
export default class IssueList extends Vue {
  @Prop() show!: boolean;

  @Watch('show')
  setShow() {
    this.showDialog = this.show;
  }

  showDialog: boolean = false;
  mapIssues: LookupIssue[] = [];

  setProp(newValue: boolean) {
    this.showDialog = newValue;
    this.$emit('show', this.showDialog);
    this.$emit('update:show', this.showDialog);
  }

  data() {
    return {
      mapIssue: null,
    };
  }

  @Action('FETCH_MAP_ISSUE')
  fetchMapIssue!: ({ issueId, citizenId }: { issueId: string; citizenId: string }) => Promise<HttpResponse>;

  get getMapIssue() {
    if (this.$data.mapIssue) {
      return this.$data.mapIssue;
    } else {
      return new Issue();
    }
  }

  formatDate(date: string) {
    return new Date(date).toLocaleString(localStorage.lang);
  }

  openIssue(issueId: string) {
    this.fetchMapIssue({ issueId: encodeURIComponent(issueId), citizenId: localStorage.citizenId }).then(response => {
      this.$data.mapIssue = response.data;
      this.showDialog = true;
    });
  }

  closeIssue() {
    this.$data.mapIssue = null;
  }
}
