









































































































import { Component, Vue } from 'vue-property-decorator';
import SwecoInput from '@/components/input/SwecoInput.vue';
import SwecoTextarea from '@/components/input/SwecoTextarea.vue';
import SwecoTimeLine from '@/components/input/SwecoTimeLine.vue';
import { Getter } from 'vuex-class';
import StatusBar from '@/components/StatusBar.vue';
import ActiveItem from '@/models/activeItem';
import Config from '@/models/citizenConfig';
import httpClient from '@/networking/httpclient';
import Form from '@/models/form';

@Component({
  components: {
    SwecoInput,
    SwecoTextarea,
    SwecoTimeLine,
    StatusBar,
  },
  validations: {},
})
export default class ShowIssue extends Vue {
  //@ts-ignore
  issueMapSrc: string = MAP_URL + '/' + localStorage.citizenId + '/show/?id=' + encodeURIComponent(localStorage.issueId);
  //@ts-ignore
  attachmentViewUrl: string = ATTACHMENT_VIEW_URL;
  //@ts-ignore
  attachmentDownloadUrl: string = ATTACHMENT_DOWNLOAD_URL;

  @Getter('getActiveItem')
  activeItem!: ActiveItem;

  @Getter('getConfig')
  config!: Config;

  formatDate() {
    return this.activeItem.observationTime.value.toLocaleString(localStorage.lang);
  }

  get isCommentEnabled() {
    return this.config.citizenCommentEnabled;
  }
  get isFileUploadEnabled() {
    return this.config.citizenFileUploadEnabled;
  }

  form: Form | null = null;
  formResponseText: string = '';

  get currentform() {
    return this.form;
  }

  get currentActiveItem() {
    return this.activeItem;
  }

  mounted() {
    if (this.activeItem && this.activeItem.category && this.activeItem.category.id) {
      httpClient
        .get(
          'citizen/citizendeeplinkform?categoryId=' +
            this.activeItem.category.id +
            '&statusId=' +
            this.activeItem.statusId +
            '&clientId=' +
            this.config.clientId
        )
        .then(response => {
          if (response && response.data) {
            this.form = response.data;
          } else {
            this.form = null;
          }
        })
        .catch(response => {
          this.formResponseText = 'Kunne ikke hente formular';
        });
    }
  }
}
