import AddressObject from './addressObject';
import { ElementTypeCombined } from './categoryGroupResponse';
import Attachment from './attachment';
export default class ActiveItem {
  id: string = '';
  category: ElementTypeCombined | null = null;
  description: Description = new Description();
  reporterName: ReporterName = new ReporterName();
  reporterAddress: ReporterAddress = new ReporterAddress();
  reporterEmail: ReporterEmail = new ReporterEmail();
  reporterPhone: ReporterPhone = new ReporterPhone();
  notificationEmail: boolean = false;
  notificationSms: boolean = false;
  observationTime: ObservationTime = new ObservationTime();
  consent: boolean = false;
  addressObject: AddressObject = new AddressObject();
  attachments: Attachments = new Attachments();
  friendlyId: string = '';
  status: string = '';
  statusId: string = '';
  comments: Comment[] = [];
  formFieldValues: FormFieldValues = new FormFieldValues();
  wkt: string = '';
}
export class ObservationTime {
  value: Date = new Date();
  required: boolean = false;
  enabled: boolean = false;
}
export class Attachments {
  values: Attachment[] = [];
  required: boolean = false;
}
export class ReporterName {
  value: string = '';
  required: boolean = false;
  enabled: boolean = false;
}
export class ReporterAddress {
  value: string = '';
  required: boolean = false;
  enabled: boolean = false;
}
export class ReporterEmail {
  value: string = '';
  required: boolean = false;
  enabled: boolean = false;
}
export class ReporterPhone {
  value: string = '';
  required: boolean = false;
  enabled: boolean = false;
}
export class Description {
  value: string = '';
  required: boolean = false;
  enabled: boolean = false;
}
export class Comment {
  id: string = '';
  text: string = '';
  public: boolean = false;
  createdBy: string = '';
  createdById: string = '';
  createdDate: string = '';
  createdDateUniversal: string = '';
}
export class FormFieldValues {
  entityId: string = '';
  encryptedEntityId: string = '';
  fieldValues: FieldValue[] = [];
}
export class FieldValue {
  fieldId: string = '';
  label: string = '';
  value: string = '';
  valueStrings: string[] = [];
  show: boolean = false;
  imgId: string = '';
}
