






































import { Prop, Vue, Component } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { MessageBus } from '@/components/MessageBus';
import SwecoSelect from '@/components/input/SwecoSelect.vue';
import { Guid } from 'guid-typescript';

@Component({
  components: {
    SwecoSelect,
  },
})
export default class SwecoTextarea extends Vue {
  @Prop({ default: '' }) text!: string;
  @Prop({ default: '4' }) rows!: string;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: null }) validations!: vuelidate.Params;
  @Prop({ default: '' }) label!: string;

  id: string = '';
  validationError: string = '';
  hasFocus: boolean = false;
  disableValidation: boolean = false;

  focus() {
    this.hasFocus = true;
  }

  blur() {
    this.hasFocus = false;

    this.validateInput();
  }

  setFocus() {
    const textareaField = this.$refs.textareaField as HTMLElement;
    textareaField.focus();
  }

  created() {
    this.id = `#textarea-${Guid.create().toString()}`;

    MessageBus.$on('validate', this.onValidate);
    MessageBus.$on('resetValidation', this.onResetValidation);
  }

  beforeDestroy() {
    MessageBus.$off('validate', this.onValidate);
    MessageBus.$off('resetValidation', this.onResetValidation);
  }

  private onValidate() {
    this.validateInput();
  }

  private onResetValidation() {
    this.validationError = '';
    const textareaField = this.$refs.textareaField as HTMLElement;
    if (textareaField != null) {
      textareaField.setAttribute('aria-invalid', 'false');
    }
  }

  validateInput() {
    this.validationError = '';
    const textareaField = this.$refs.textareaField as HTMLElement;
    if (textareaField != null) {
      textareaField.setAttribute('aria-invalid', 'false');
    }

    if (this.validations) {
      if (!this.validationError && this.validations.required === false) {
        this.validationError = this.$t.citizenTranslations.citizenDetailsErrorRequired;
        if (textareaField != null) {
          textareaField.setAttribute('aria-invalid', 'true');
        }
      }
    }
  }

  get isRequired() {
    return this.validations != null && this.validations.$params != null && this.validations.$params.required;
  }

  get requireText() {
    return this.isRequired && !this.disableValidation
      ? '* <span style="font-size: 10px;">(' + this.$t.citizenTranslations.citizenDetailsErrorRequired + ')</span>'
      : '';
  }

  get currentMax() {
    // return this.validations && this.validations.$params.maxLength ? this.validations.$params.maxLength.max : undefined;
    return 350;
  }

  get currentLength() {
    return this.text ? this.text.length : 0;
  }

  public setDisableValidation(disable: boolean) {
    //Hvis vi ønsker at fjerne valideringen (og dermed stjernen) på baggrund af et andet input, er det tilsyneladende ikke muligt at fjerne en validation fra opsætningen når vi bruger typescript
    //i opsætningen kan man ikke tilgå komponent instansen, og i komponent instancen er der ikke nogen officielt vuelidate måde at tilføje/fjerne en validering. Det er måske muligt med et grimt hack
    //men så hellere denne løsning.
    this.disableValidation = disable;
  }

  input(event: any) {
    this.$emit('update:text', event.target.value);
    if (this.validationError) {
      this.validateInput();
    }
  }
}
