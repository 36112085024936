






import { Component, Prop, Vue } from 'vue-property-decorator';
import ShowIssue from '@/components/show/ShowIssue.vue';

@Component({
  components: {
    ShowIssue,
  },
})
export default class Show extends Vue {}
