























import { Prop, Vue, Component, Watch } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { MessageBus } from '@/components/MessageBus';
import { Guid } from 'guid-typescript';

@Component
export default class SwecoRadioButton extends Vue {
  @Prop({ default: '' }) id!: string;
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) heading!: string;
  @Prop({ default: '' }) name!: string;
  @Prop({ default: false }) checked!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: null }) validations!: vuelidate.Params;

  tempId: string = 'input' + Guid.create().toString();
  validationError: string = '';

  blur() {
    this.validateInput();
  }

  get getName() {
    return this.name;
  }

  created() {
    MessageBus.$on('validate', this.onValidate);
    MessageBus.$on('resetValidation', this.onResetValidation);
  }

  beforeDestroy() {
    MessageBus.$off('validate', this.onValidate);
    MessageBus.$off('resetValidation', this.onResetValidation);
  }

  private onValidate() {
    this.validateInput();
  }

  private onResetValidation() {
    this.validationError = '';
    const radioButton = this.$refs.radioButton as HTMLElement;
    if (radioButton != null) {
      radioButton.setAttribute('aria-invalid', 'false');
    }
  }

  validateInput() {
    this.validationError = '';
    const radioButton = this.$refs.radioButton as HTMLElement;
    if (radioButton != null) {
      radioButton.setAttribute('aria-invalid', 'false');
    }

    if (this.validations) {
      if (!this.validationError && this.validations.required === false) {
        this.validationError = this.$t.citizenTranslations.citizenDetailsErrorRequired;
        if (radioButton != null) {
          radioButton.setAttribute('aria-invalid', 'true');
        }
      }
    }
  }

  input(event: any) {
    this.$emit('input', this.id);
    this.$emit('update:value', this.id);
    if (this.validationError) {
      this.validateInput();
    }
  }
}
