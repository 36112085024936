





















import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { HttpResponse } from '@/networking/interfaces';
import SwecoModal from '@/components/input/SwecoModal.vue';

@Component({
  components: {
    SwecoModal,
  },
})
export default class ZoomToGeometry extends Vue {
  @Prop() show!: boolean;

  @Watch('show')
  setShow() {
    this.showDialog = this.show;
  }
  showDialog: boolean = false;
  lon: string = '';
  lat: string = '';
  map!: any;

  setProp(newValue: boolean) {
    this.showDialog = newValue;
    this.$emit('show', this.showDialog);
    this.$emit('update:show', this.showDialog);
  }

  sendMessage() {
    const map = this.map;
    const msg = '{"event": "zoomToGeometry", "lon": ' + this.lon + ', "lat": ' + this.lat + '}';
    map.contentWindow.postMessage(msg, '*');
    this.setProp(false);
  }
}
