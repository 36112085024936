import { LookupFormValue } from './lookupItem';

export default class Form {
  id: string = '';
  name: string = '';
  isLocal: boolean = false;
  containers: Container[] = [];
}

export class Container {
  id: string = '';
  label: string = '';
  border: boolean = false;
  collapsed: boolean = false;
  showLabel: boolean = false;
  horizontalList: boolean = true;
  fields: Field[] = [];
}

export class Field {
  id: string = '';
  label: string = '';
  visibleLabel: boolean = false;
  required: boolean = false;
  horizontalList: boolean = false;
  showInDetails: boolean = false;
  showInHistory: boolean = false;
  value: string = '';
  defaultValue: string = '';
  show: boolean = false;
  type: FieldType = new FieldType();
  dependsOn: DependsOnField = new DependsOnField();
}

export class FieldType {
  id: string = '';
  name: string = '';
  regex: string = '';
  validationMin: number | null = null;
  validationMax: number | null = null;
  validationDecimal: number | null = null;
  isLocal: boolean = false;
  controlType: ControlType = new ControlType();
  values: LookupFormValue[] = [];
}

export class ControlType {
  key: string = '';
  name: string = '';
  isCollection: boolean = false;
}

export class DependsOnField {
  id: string = '';
  label: string = '';
  equalValue: string = '';
  type: FieldType = new FieldType();
}
