









































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component
export default class SwecoModal extends Vue {
  @Prop({ default: '' })
  header!: string;

  @Prop({ default: '' })
  classes!: string;

  mounted() {
    //Dette er nødvendigt sammen med tabindex for at kunne lukke modalen med escape uden at sætte et event i roden af Miljøweb
    //Det er desuden vigtigt at bruge v-if og ikke v-show så mounted bliver kaldt
    const ele = this.$refs.focusMe as HTMLDivElement;
    if (ele) {
      ele.focus();
    }

    window.addEventListener('resize', this.fixModalSize);
    this.fixModalSize();
  }

  unmounted() {
    window.removeEventListener('resize', this.fixModalSize);
  }

  fixModalSize() {
    const divModalBackgroundMask = this.$refs.focusMe as HTMLDivElement;
    if (divModalBackgroundMask) {
      const clientHeight = divModalBackgroundMask.clientHeight;
      const divModalContent = divModalBackgroundMask.children[0].children[0].children[0] as HTMLDivElement;
      const divBody = divModalContent.children[1] as HTMLDivElement;
      const divHeader = divModalContent.children[0] as HTMLDivElement;
      const divFooter = divModalContent.children[2] as HTMLDivElement;
      let offsetTop = 0;
      if (divModalContent.parentElement != null) {
        offsetTop = divModalContent.parentElement.offsetTop;
      }
      const divBodyHeight = clientHeight - divHeader.clientHeight - divFooter.clientHeight - 2 * offsetTop + 'px';
      divBody.style.height = divBodyHeight;
    }
  }

  get customCssClasses() {
    const classes = this.classes.split('s+');
    const entries = new Map<string, boolean>();

    for (const cls of classes) {
      entries.set(cls, true);
    }

    return Object.fromEntries(entries);
  }

  private onCloseClicked() {
    this.$emit('close');
  }
}
