import moment from 'moment';
import AddressObject from './addressObject';
import Attachment from './Attachment';
import { Comment } from './activeItem';
export default class Issue {
  categoryId: string = '';
  category: string = '';
  elementType: string = '';
  originId: string = '89c1868c-ff57-4a89-8090-65e27cf8e687'; // Citizen
  priorityId: string = '094e42ce-806f-4f8c-b611-6dbeb1a28ae1'; // Normal
  reporterTypeId: string = '';
  isPublic: boolean = false;
  description: string = '';
  reporterName: string = '';
  reporterEmail: string = '';
  reporterPhone: string = '';
  reporterAddress: string = '';
  notificationEmail: boolean = false;
  notificationSms: boolean = false;
  ObservationTime: string = '';
  clientId: string = '';
  createdBy: string = '';
  consentId: string = '';
  addressObject: AddressObject | null = null;
  attachments: Attachment[] = [];
  comments: Comment[] = [];
  createdDate: string = '';
  friendlyIdFormatted: string = '';
  statusId: string = '';
  status: string = '';
  locationNear: string = '';
  altLocationNear: string | null = '';
  responsibleId: string = '';
  assignedToId: string = '';
}

export class Geometry {
  wkt: string = '';
  srid: string = '';
}
