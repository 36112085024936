export default class Attachment {
  id: string = '';
  content: any | null = null;
  contentType: string = '';
  fileName: string = '';
  fileSize: number = 0;
  mimeType: string = '';
}

export class UploadAttachmentsRequest {
  attachments: Attachment[] = [];
}
