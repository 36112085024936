import ActiveItem, { Attachments, Comment } from '@/models/activeItem';
import { ElementTypeCombined } from '@/models/categoryGroupResponse';
import moment from 'moment';

export default class Mapping {
  issueToActiveItem(data: any) {
    const ai = new ActiveItem();
    ai.reporterName.value = data.reporterName;
    ai.reporterAddress.value = data.reporterAddress;
    ai.reporterPhone.value = data.reporterPhone;
    ai.reporterEmail.value = data.reporterEmail;
    ai.description.value = data.description;
    const comments: Comment[] = [];
    for (const comment of data.comments) {
      comment.createdDate = moment().format(comment.createdDate);
      comments.push(comment);
    }
    ai.comments = comments;

    var attachments = new Attachments();
    attachments.values = data.attachments;
    ai.attachments = attachments;

    ai.friendlyId = data.friendlyIdFormatted;
    ai.addressObject.locationNear = data.locationNear;
    ai.status = data.status;
    ai.statusId = data.statusId;
    ai.observationTime.value = new Date(data.createdDate);

    ai.category = new ElementTypeCombined();
    ai.category.categoryName = data.category;
    ai.category.elementName = data.elementType;
    ai.category.id = data.categoryId ? data.categoryId : '';

    ai.id = data.id;

    return ai;
  }
}
