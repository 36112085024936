



























import { Prop, Vue, Component, Watch } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { MessageBus } from '@/components/MessageBus';
import { mixins } from 'vue-class-component';
import { Guid } from 'guid-typescript';
import SwecoModal from '@/components/input/SwecoModal.vue';

@Component({
  components: {
    SwecoModal,
  },
})
export default class SwecoCheckbox extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: null }) validations!: vuelidate.Params;
  @Prop({ default: false }) readHere!: boolean;
  @Prop({ default: '' }) readHereText!: string;
  @Prop({ default: '' }) modalText!: string;
  @Prop({ default: false }) required!: boolean;

  showModal: boolean = false;
  disableValidation: boolean = false;

  tempId: string = 'input' + Guid.create().toString();

  validationError: string = '';
  get getReadHere() {
    return this.readHere;
  }
  blur() {
    this.validateInput();
  }

  created() {
    MessageBus.$on('validate', this.onValidate);
    MessageBus.$on('resetValidation', this.onResetValidation);
  }

  beforeDestroy() {
    MessageBus.$off('validate', this.onValidate);
    MessageBus.$off('resetValidation', this.onResetValidation);
  }

  private onValidate() {
    this.validateInput();
  }

  private onResetValidation() {
    this.validationError = '';
    const checkboxField = this.$refs.checkboxField as HTMLElement;
    if (checkboxField != null) {
      checkboxField.setAttribute('aria-invalid', 'false');
    }
  }

  validateInput() {
    this.validationError = '';
    const checkboxField = this.$refs.checkboxField as HTMLElement;
    if (checkboxField != null) {
      checkboxField.setAttribute('aria-invalid', 'false');
    }

    if (this.validations && !this.disableValidation) {
      if (!this.validationError && this.validations.accepted === false) {
        this.validationError = this.$t.citizenTranslations.citizenDetailsErrorRequired;
        if (checkboxField != null) {
          checkboxField.setAttribute('aria-invalid', 'true');
        }
      }
    }
    if (this.required && !this.value) {
      this.validationError = this.$t.citizenTranslations.citizenDetailsErrorRequired;
      if (checkboxField != null) {
        checkboxField.setAttribute('aria-invalid', 'true');
      }
    }
  }
  // IE understøtter IKKE @input. Det skal være @change
  input(event: any) {
    this.$emit('input', event.target.checked);
    this.$emit('update:value', event.target.checked);
  }

  public setDisableValidation(disable: boolean) {
    this.disableValidation = disable;
  }
}
