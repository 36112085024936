




















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ActiveItem from '@/models/activeItem';
import Config, { Tab } from '@/models/citizenConfig';
import SwecoInput from '@/components/input/SwecoInput.vue';
import SwecoCheckbox from '@/components/input/SwecoCheckbox.vue';
import SwecoTextarea from '@/components/input/SwecoTextarea.vue';
import SwecoUpload from '@/components/input/SwecoUpload.vue';

@Component({
  components: {
    SwecoInput,
    SwecoTextarea,
    SwecoCheckbox,
    SwecoUpload,
  },
  validations: {
    activeItem: {
      consent: {
        accepted: val => val === true,
      },
    },
  },
})
export default class Step2 extends Vue {
  mapAlert: boolean = false;
  @Getter('getActiveItem')
  activeItem!: ActiveItem;

  @Getter('getConfig')
  config!: Config;

  @Prop() tab!: Tab;
  @Prop() currentTab!: number;

  showConsentText: boolean = false;
  issueMapSrc: string = '';

  get getAttachments() {
    return this.activeItem.attachments.values;
  }

  get getForm() {
    return this.activeItem.formFieldValues;
  }

  mounted() {
    setTimeout(() => {
      if (this.config.consentId == null) {
        this.activeItem.consent = true;
      }
    }, 2000);
  }

  get isConsentRequired() {
    if (this.config) {
      if (this.config.consentId != null) {
        return true;
      }
    }
    return false;
  }

  get isMailEnabled() {
    if (this.config) {
      return this.config.enableMailNotification;
    }
    return false;
  }

  get isSmsEnabled() {
    if (this.config) {
      return this.config.enableSmsNotification;
    }
    return false;
  }

  updateIssueMapSrc(currentTab: number) {
    //@ts-ignore
    this.issueMapSrc = MAP_URL + '/' + localStorage.citizenId;
    this.issueMapSrc +=
      '?wkt=' +
      this.activeItem.addressObject.addressObject.wkt +
      '&step=' +
      currentTab +
      '&addressServiceKey=' +
      this.config.mapSearchSettings.geoSearchServices[0].serviceKey.toLowerCase() +
      '&setPosition=true';
  }
}
