




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MessageBus } from '@/components/MessageBus';
import { Action } from 'vuex-class';
import Issue from '@/models/issue';
import Config from '@/models/citizenConfig';
import ActiveItem, { FormFieldValues } from '@/models/activeItem';
import { HttpResponse } from '@/networking/interfaces';

@Component({
  components: {},
  validations: {},
})
export default class Step3 extends Vue {
  loading: boolean = true;
  responseSuccess: boolean = true;

  @Prop() tab!: number;
  @Prop() currentTab!: number;

  data() {
    return {
      responseText: '',
      responseTitle: '',
    };
  }

  created() {
    MessageBus.$on('loading', (e: boolean) => {
      this.loading = e;
    });
  }

  @Action('SAVE_ISSUE')
  saveIssueAction!: (issue: Issue) => Promise<HttpResponse>;
  @Action('SAVE_FORM_FIELD_VALUES')
  saveFormFieldValues!: (formFieldValues: FormFieldValues) => Promise<HttpResponse>;

  saveIssue(activeItem: ActiveItem, config: Config) {
    MessageBus.$emit('loading', true);
    const newIssue = new Issue();
    if (activeItem.category) {
      newIssue.categoryId = activeItem.category.id;
    }
    // tslint:disable-next-line:no-debugger
    newIssue.reporterName = activeItem.reporterName.value;
    newIssue.reporterAddress = activeItem.reporterAddress.value;
    newIssue.reporterEmail = activeItem.reporterEmail.value;
    newIssue.reporterPhone = activeItem.reporterPhone.value;
    newIssue.description = activeItem.description.value;
    newIssue.consentId = config.consentId;
    newIssue.isPublic = config.isPublic;
    newIssue.ObservationTime = activeItem.observationTime.value.toUTCString();
    newIssue.addressObject = activeItem.addressObject;
    newIssue.clientId = config.clientId;
    newIssue.attachments = activeItem.attachments.values;
    newIssue.notificationEmail = activeItem.notificationEmail;
    newIssue.notificationSms = activeItem.notificationSms;
    newIssue.altLocationNear = activeItem.addressObject.altLocationNear == '' ? null : activeItem.addressObject.altLocationNear;

    newIssue.assignedToId = '00000000-0000-0000-0000-000000000000';
    newIssue.responsibleId = '00000000-0000-0000-0000-000000000000';

    this.saveIssueAction(newIssue).then(response => {
      if (response) {
        if (activeItem.formFieldValues.fieldValues.length > 0) {
          activeItem.formFieldValues.entityId = response.data.issueId;
          this.saveFormFieldValues(activeItem.formFieldValues).then(formFieldResponse => {
            if (formFieldResponse) {
              MessageBus.$emit('loading', false);
              this.responseSuccess = true;
              this.$data.responseTitle = this.$t.citizenTranslations.citizenDetailsSubmitResponseSuccess + '!';
              this.$data.responseText = response.data.responseMessage;
            } else {
              MessageBus.$emit('loading', false);
              this.responseSuccess = false;
              this.$data.responseTitle = this.$t.citizenTranslations.citizenDetailsSubmitResponseError + '!';
              this.$data.responseText = response.data.responseMessage;
            }
          });
        } else {
          MessageBus.$emit('loading', false);
          this.responseSuccess = true;
          this.$data.responseTitle = this.$t.citizenTranslations.citizenDetailsSubmitResponseSuccess + '!';
          this.$data.responseText = response.data.responseMessage;
        }
      } else {
        MessageBus.$emit('loading', false);
        this.responseSuccess = false;
        this.$data.responseTitle = this.$t.citizenTranslations.citizenDetailsSubmitResponseError + '!';
        this.$data.responseText = '';
      }
    });
  }
}
