


























import { Prop, Vue, Component, Watch } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Guid } from 'guid-typescript';
import * as vuelidate from 'vuelidate/lib/validators';
import { MessageBus } from '@/components/MessageBus';
import 'vue2-datepicker/locale/da';

@Component({
  components: {
    DatePicker,
  },
})
export default class SwecoDatepicker extends Vue {
  @Prop({ default: '' }) value!: Date;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: null }) lang!: string;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: null }) validations!: vuelidate.Params;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: false }) floatRight!: boolean;
  @Prop({ default: true }) limited!: boolean;

  inputFieldId: string = 'datepicker-' + Guid.create().toString();
  localDate!: Date;
  validationError: string = '';
  defaultValue: Date = new Date();

  @Watch('value', { deep: true, immediate: true })
  onDatePropUpdate(newValue: Date) {
    this.defaultValue = newValue;
  }
  mounted() {
    const dateTimeInputField = document.getElementsByClassName('datetimeInputField');
    if (dateTimeInputField.length > 0) {
      dateTimeInputField[0].setAttribute('aria-label', this.label);
    }
    MessageBus.$on('validate', () => {
      this.validateInput();
    });
    MessageBus.$on('resetValidation', () => {
      this.validationError = '';
      const datePickerField = this.$refs.datePickerField as Vue & { $el: () => any };
      if (datePickerField != null) {
        datePickerField.$el.setAttribute('aria-invalid', 'false');
      }
    });
  }

  input(event: any) {
    this.$emit('input', this.defaultValue);
    this.$nextTick(() => {
      this.validateInput();
    });
  }

  disableDaysAfterToday(date: Date) {
    if (!this.limited) {
      return null;
    }

    const tomorrow = new Date();
    return date > tomorrow;
  }

  get isRequired() {
    if (this.required) {
      return this.required;
    } else {
      return (
        this.validations != null &&
        this.validations.$params != null &&
        this.validations.$params.required &&
        this.validations.$params.required.type === 'required'
      );
    }
  }

  get requireText() {
    return this.isRequired
      ? '* <span style="font-size: 10px;">(' + this.$t.citizenTranslations.citizenDetailsErrorRequired + ')</span>'
      : '';
  }

  validateInput() {
    this.validationError = '';
    const datePickerField = this.$refs.datePickerField as Vue & { $el: () => any };
    if (datePickerField != null) {
      datePickerField.$el.setAttribute('aria-invalid', 'false');
    }

    if (this.required) {
      if (!this.validationError && !this.value) {
        this.validationError = this.$t.citizenTranslations.citizenDetailsErrorRequired;
        if (datePickerField != null) {
          datePickerField.$el.setAttribute('aria-invalid', 'true');
        }
      }
    } else {
      if (this.validations) {
        if (!this.validationError && this.validations.required === false) {
          this.validationError = this.$t.citizenTranslations.citizenDetailsErrorRequired;
          if (datePickerField != null) {
            datePickerField.$el.setAttribute('aria-invalid', 'true');
          }
        }
      }
    }
  }
}
