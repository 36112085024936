export default class CategoryGroupResponse {
  categoryGroupSeparated: CategoryGroup[] = [];
  categoryGroupElementTypeCombined: CategoryGroup[] = [];
  categoryGroupCombined: ElementTypeCombined[] = [];
}

export class CategoryGroup {
  id: string = '';
  name: string = '';
  sortOrder: number = 0;
  elementTypes: ElementType[] = [];
  elementTypesCombined: ElementTypeCombined[] = [];
}

export class ElementType {
  id: string = '';
  name: string = '';
  categories: ElementTypeCombined[] = [];
}

export class Category {
  id: string = '';
  name: string = '';
}

export class ElementTypeCombined {
  id: string = '';
  name: string = '';
  elementName: string = '';
  categoryName: string = '';
  citizenLink: CitizenLink = new CitizenLink();
}

export class CitizenLink {
  url: string = '';
  name: string = '';
  description: string = '';
}
