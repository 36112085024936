export default class AddressObject {
  locationNear: string = '';
  altLocationNear: string = '';
  inputLocationNear: string = '';
  typeEnum: number = 0;
  addressObject: Address = new Address();
  valid: boolean = false;
}
export class Address {
  id: string = '';
  status: number = 0;
  vejnavn: string = '';
  vejkode: string = '';
  adresseringsvejnavn: string = '';
  husnr: string = '';
  etage: string = '';
  dør: string = '';
  adressebetegnelse: string = '';
  kommunenavn: string = '';
  postnr: string = '';
  postnrnavn: string = '';
  kommunekode: string = '';
  wkt: string = '';
  href: string = '';
  x: string = '';
  y: string = '';
  typeEnum: number = 0;
}
