






import { Component, Prop, Vue } from 'vue-property-decorator';
import Wizard from '@/components/wizard/Wizard.vue';

@Component({
  components: {
    Wizard,
  },
})
export default class Home extends Vue {
  @Prop({ default: 'wizard' }) type!: string;
}
