export default class CitizenConfig {
  consentId: string = '';
  consentText: string = '';
  consentTitle: string = '';
  consentConfirmLabel: string = '';
  consentReadHere: string = '';
  clientId: string = '';
  mapSearchSettings: MapSearchSettings = new MapSearchSettings();
  userId: string = '';
  categoryGroupId: string = '';
  requiredAttachments: boolean = false;
  requiredComments: boolean = false;
  requiredEmail: boolean = false;
  requiredAddress: boolean = false;
  requiredName: boolean = false;
  requiredPhone: boolean = false;
  enableComments: boolean = false;
  enableEmail: boolean = false;
  enableName: boolean = false;
  enablePhone: boolean = false;
  enableAddress: boolean = false;
  enableSmsNotification: boolean = false;
  enabledLocationNear: boolean = false;
  enableAutoPlaceMarker: boolean = false;
  smsNotificationPreselected: boolean = false;
  smsNotificationText: string = '';
  enableMailNotification: boolean = false;
  mailNotificationPreselected: boolean = false;
  mailNotificationText: string = '';
  elementChooser: number = 2;
  splashText: string = '';
  enableObservationTime: boolean = false;
  requiredObservationTime: boolean = false;
  isPublic: boolean = false;
  enableStatusBar: boolean = false;
  citizenCommentEnabled: boolean = false;
  citizenFileUploadEnabled: boolean = false;
  lang: string = 'da';
  regexValidationPhone: string = '';
  tabs: Tab[] = [new Tab()];
  static mapSearchSettings: any;
  showSwecoLogo: boolean = true;
  disableMap: boolean = false;
  accessibilityStatementUrl: string = '';
}

export class Tab {
  id: string = '';
  title: string = '';
  text: string = '';
  sortorder: number = 0;
}

export class MapSearchSettings {
  apiKey: string = '';
  ticket: string = '';
  srid: number = 0;
  geoSearchServices: GeoSearchService[] = [new GeoSearchService()];
}

export class GeoSearchService {
  serviceKey: string = '';
  area: string = '';
  baseUrl: string = '';
  typeName: string = '';
}

export class Translations {
  citizenDetailsMapHelp: string = '';
  citizenDetailsShieldIssueReceived: string = '';
  citizenDetailsUnclarified: string = '';
  citizenDetailsShieldSolvingInProgress: string = '';
  citizenDetailsShieldSolved: string = '';
  citizenDetailsShieldCantBeSolved: string = '';
  citizenDetailsShieldYourHint: string = '';
  citizenDetailsReportedAddress: string = '';
  citizenDetailsAddressSearchPlaceholder: string = '';
  citizenDetailsSetPointInMapHelp: string = '';
  citizenDetailsCategory: string = '';
  citizenDetailsElement: string = '';
  citizenDetailsSubject: string = '';
  citizenDetailsObservationTime: string = '';
  citizenDetailsReporterName: string = '';
  citizenDetailsNamePlaceholder: string = '';
  citizenDetailsReporterAddress: string = '';
  citizenDetailsAddressPlaceholder: string = '';
  citizenDetailsReporterEmail: string = '';
  citizenDetailsEmailPlaceholder: string = '';
  citizenDetailsReporterPhone: string = '';
  citizenDetailsPhonePlaceholder: string = '';
  citizenDetailsFiles: string = '';
  citizenDetailsChooseFile: string = '';
  citizenDetailsAllowedFiletypes: string = '';
  citizenDetailsFormError: string = '';
  citizenDetailsLocationNear: string = '';
  citizenDetailsStatus: string = '';
  citizenDetailsDescription: string = '';
  citizenDetailsDescriptionPlaceholder: string = '';
  citizenDetailsComments: string = '';
  citizenDetailsEmptyList: string = '';
  citizenDetailsNoResults: string = '';
  citizenDetailsOk: string = '';
  citizenDetailsNext: string = '';
  citizenDetailsBack: string = '';
  citizenDetailsClose: string = '';
  citizenDetailsOpen: string = '';
  citizenDetailsBtnNewHint: string = '';
  citizenDetailsAddComment: string = '';
  citizenDetailsHintSubmissionDate: string = '';
  citizenDetailsHintId: string = '';
  citizenDetailsBtnSubmitHint: string = '';
  citizenDetailsSubmitResponseSuccess: string = '';
  citizenDetailsSubmitResponseError: string = '';
  citizenVotesSubmitResponseSuccess: string = '';
  citizenVotesSubmitResponseError: string = '';
  citizenDetailsNoComments: string = '';
  citizenDetailsSend: string = '';
  citizenDetailsErrorRequired: string = '';
  citizenDetailsErrorEmail: string = '';
  citizenDetailsErrorPhone: string = '';
  citizenDetailsCommenRegexError: string = '';
  citizenDetailsChooseDate: string = '';
  citizenDetailsErrorFileRequired: string = '';
  citizenDetailsFileBtnText: string = '';
  citizenDetailsSelectPlaceholder: string = '';
  citizenDetailsSelectCategoryPlaceholder: string = '';
  citizenDetailsSelectElementPlaceholder: string = '';
  citizenDetailsSelectAccessAddres: string = '';
  citizenDetailsZoomForYou: string = '';
  citizenDetailsYes: string = '';
  citizenDetailsNo: string = '';
  citizenDetailsAccessibility: string = '';
  citizenDetailsNoCommentsImageHint: string = '';
  citizenDetailsOpenIssue: string = '';
  citizenDetailsCloseIssue: string = '';
  citizenDetailsCitizenLink: string = '';
  citizenDetailsIssueList: string = '';
  citizenDetailsZoomToGeometry: string = '';
  citizenDetailsWelcome: string = '';
}
