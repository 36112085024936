
















































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import * as vuelidate from 'vuelidate/lib/validators';
import { MessageBus } from '@/components/MessageBus';
import httpClient, { BASE_URL, BASE_API_URL } from '@/networking/httpclient';
import ActiveItem from '@/models/activeItem';
import { Getter } from 'vuex-class';
import { Guid } from 'guid-typescript';
import axios from 'axios';
import AddressObject, { Address } from '@/models/addressObject';

@Component
export default class LocalAddressSearch extends Vue {
  @Prop({ default: 'id' }) trackBy!: string;
  @Prop({ default: 'name' }) label!: string;
  @Prop({ default: undefined }) heading!: string;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: () => [] }) value!: any[] | any;
  @Prop({ default: null }) validations!: vuelidate.Params;
  @Prop({ default: '' }) localAddressUrl!: string;
  @Prop({ default: 0 }) srid!: number;
  @Prop({ default: '' }) area!: string;
  @Prop({ default: 'local' }) type!: string;

  validationError: string = '';
  localoptions: any[] = [];
  isLoading: boolean = false;
  localValue: any[] | any = {};
  originalValue: any | null = null;
  open: boolean = false;
  mouseInDiv: boolean = false;

  id: string = 'local-address-input' + Guid.create().toString();

  @Getter('getActiveItem')
  activeItem!: ActiveItem;

  get getLocalOptions() {
    return this.localoptions;
  }

  @Watch('value')
  onValueChange(newValue: any[] | any) {
    this.localValue.name = newValue;
  }

  @Watch('activeItem.addressObject.locationNear')
  setLocationNear() {
    this.setInput(this.activeItem.addressObject.locationNear);
  }

  @Watch('activeItem.addressObject.altLocationNear')
  setAltLocationNear() {
    this.setInput(this.activeItem.addressObject.altLocationNear);
  }
  setInput(locationNear: string) {
    if (locationNear) {
      this.$emit('update:value', locationNear);
    }
    this.open = true;
    this.validateInput();
  }

  setMousePosition() {
    this.mouseInDiv = !this.mouseInDiv;
  }
  created() {
    MessageBus.$on('validate', () => {
      this.validateInput();
      this.validateScrollTo();
    });
    MessageBus.$on('resetValidation', () => {
      this.validationError = '';
      const inputField = this.$refs.inputField as HTMLElement;
      if (inputField != null) {
        inputField.setAttribute('aria-invalid', 'false');
      }
      this.originalValue = null;
    });
  }

  down() {
    if (this.localoptions.length > 0) {
      const index = this.localoptions.findIndex(x => x.active == true);
      if (index != this.localoptions.length - 1) {
        this.localoptions[index].active = false;
        this.localoptions[index + 1].active = true;
      }
    }
  }

  up() {
    if (this.localoptions.length > 0) {
      const index = this.localoptions.findIndex(x => x.active == true);
      if (index != 0) {
        this.localoptions[index].active = false;
        this.localoptions[index - 1].active = true;
      }
    }
  }

  enter() {
    if (this.localoptions.length > 0) {
      const index = this.localoptions.findIndex(x => x.active == true);
      this.$emit('update:value', this.localoptions[index].name);
      this.$emit('selected', this.localoptions[index]);
      this.open = false;
      this.validateInput();
    }
  }

  focus() {
    this.open = true;
  }

  blur() {
    if (!this.mouseInDiv) {
      this.open = false;
    }
  }

  input(event: any) {
    if (event.target.value.length > 2) {
      this.open = true;
      this.asyncFind(event.target.value);
    } else if (event.target.value === '') {
      const map = this.$parent.$refs.map as any;
      const msg = '{"event": "noAddress"}';
      map.contentWindow.postMessage(msg, '*');
    }
  }

  setAddressAndValue(locationNear: string) {
    this.$emit('update:value', locationNear);
    this.validateInput();
  }

  setValue(option: any) {
    const inputField = this.$refs.inputField as HTMLElement;
    if (inputField != null) {
      inputField.setAttribute('aria-invalid', 'false');
    }
    if (this.type === 'lantmateriet') {
      httpClient
        .get(BASE_API_URL + 'citizen/AddressDetailsSe?addressId=' + option.objektIdentitet)
        .then(response => {
          option = response.data;
          option.active = true;
          this.$emit('update:value', option.adressebetegnelse);
          this.$emit('selected', option);
          this.open = false;
          this.validateInput();
        })
        .catch(resp => {
          this.validationError = resp;
          if (inputField != null) {
            inputField.setAttribute('aria-invalid', 'true');
          }
        });
    } else {
      this.$emit('update:value', option.name);
      this.$emit('selected', option);
      this.open = false;
      this.validateInput();
    }
  }

  asyncFind(query: string) {
    let url = BASE_URL;
    if (this.type === 'local') {
      url += this.localAddressUrl + '?srid=' + this.srid + '&area=' + encodeURIComponent(this.area) + '&search=' + query + '&take=5';
      this.evokeLocal(url);
    } else if (this.type === 'kartverket') {
      url = this.localAddressUrl + 'sok?sok=' + query + '*&kommunenummer=' + encodeURIComponent(this.area);
      this.evokeKartverket(url);
    } else if (this.type === 'lantmateriet') {
      url =
        this.localAddressUrl + '/distribution/produkter/belagenhetsadress/v4.2/referens/fritext?adress='+ query + ' ' + this.area + '&maxHits=10';
      this.evokeLantmateriet(url);
    }
  }

  async evokeLantmateriet(url: string) {
    const inputField = this.$refs.inputField as HTMLElement;
    if (inputField != null) {
      inputField.setAttribute('aria-invalid', 'false');
    }
    httpClient
      .get(BASE_API_URL + 'citizen/AddressSearchSe?url=' + url)
      .then(response => {
        this.localoptions = [];
        for (let index = 0; index < response.data.length; index++) {
          const addressSe = response.data[index];
          response.data[index].active = index == 0 ? true : false;
          response.data[index].id = index.toString();
          response.data[index].name = addressSe.beteckning;
          // response.data[index].wkt = 'POINT(' + addressNo.representasjonspunkt.lon + ' ' + addressNo.representasjonspunkt.lat +')';
        }
        this.localoptions = response.data;
        this.isLoading = false;
        this.$emit('select');
      })
      .catch(resp => {
        this.validationError = resp;
        if (inputField != null) {
          inputField.setAttribute('aria-invalid', 'true');
        }
      });
  }

  async evokeKartverket(url: string) {
    const inputField = this.$refs.inputField as HTMLElement;
    if (inputField != null) {
      inputField.setAttribute('aria-invalid', 'false');
    }
    await axios({
      method: 'get',
      url,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        this.localoptions = [];
        // const adresser = [];
        for (let index = 0; index < response.data.adresser.length; index++) {
          const addressNo = response.data.adresser[index];
          response.data.adresser[index].active = index == 0 ? true : false;
          response.data.adresser[index].id = index.toString();
          response.data.adresser[index].name = addressNo.adressetekst + ', ' + addressNo.postnummer + ' ' + addressNo.poststed;
          response.data.adresser[index].wkt =
            'POINT(' + addressNo.representasjonspunkt.lon + ' ' + addressNo.representasjonspunkt.lat + ')';
        }
        this.localoptions = response.data.adresser;
        this.isLoading = false;
        this.$emit('select');
      })
      .catch(resp => {
        this.validationError = resp;
        if (inputField != null) {
          inputField.setAttribute('aria-invalid', 'true');
        }
      });
  }

  evokeLocal(url: string) {
    const inputField = this.$refs.inputField as HTMLElement;
    if (inputField != null) {
      inputField.setAttribute('aria-invalid', 'false');
    }
    httpClient
      .get(url)
      .then(response => {
        this.localoptions = [];
        for (let index = 0; index < response.data.length; index++) {
          response.data[index].active = index == 0 ? true : false;
        }
        this.localoptions = response.data;
        this.isLoading = false;
        this.$emit('select');
      })
      .catch(resp => {
        this.validationError = resp;
        if (inputField != null) {
          inputField.setAttribute('aria-invalid', 'true');
        }
      });
  }

  validateScrollTo() {
    if (this.validationError) {
      setTimeout(() => {
        (this.$refs.inputField as any).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 10);
    }
  }

  validateInput() {
    this.open = false;
    this.validationError = '';
    const inputField = this.$refs.inputField as HTMLElement;
    if (inputField != null) {
      inputField.setAttribute('aria-invalid', 'false');
    }

    if (this.validations) {
      if (!this.validationError && this.validations.required === false) {
        this.validationError = this.$t.citizenTranslations.citizenDetailsErrorRequired;
        if (inputField != null) {
          inputField.setAttribute('aria-invalid', 'true');
        }
      }
    }
    if (!this.validationError && this.activeItem && !this.activeItem.addressObject.valid) {
      this.validationError = this.$t.citizenTranslations.citizenDetailsSetPointInMapHelp;
      if (inputField != null) {
        inputField.setAttribute('aria-invalid', 'true');
      }
    }
  }

  get isRequired() {
    const isFieldRequired =
      this.validations != null &&
      this.validations.$params != null &&
      this.validations.$params.required &&
      this.validations.$params.required.type === 'required';

    return isFieldRequired;
  }

  get requireText() {
    return this.isRequired
      ? '* <span style="font-size: 10px;">(' + this.$t.citizenTranslations.citizenDetailsErrorRequired + ')</span>'
      : '';
  }
}
