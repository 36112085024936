import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap';
import Vuelidate from 'vuelidate';
import Component from 'vue-class-component';

import { texts } from './texts';
import 'bootstrap/dist/css/bootstrap.css';
import '@sweco/sweco-digital-platforms/dist/css/sweco-bootstrap.css';
import '@sweco/sweco-digital-platforms/dist/js/sweco-bootstrap.js';
import Toasted from 'vue-toasted';

const Multiselect = () => import('vue-multiselect');

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(Toasted);
Vue.component('multiselect', Multiselect);

Vue.prototype.$t = texts;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
