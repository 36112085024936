








































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ActiveItem, { Attachments, Comment, FieldValue, FormFieldValues } from '@/models/activeItem';
import { MessageBus } from '@/components/MessageBus';
import SwecoTextarea from '@/components/input/SwecoTextarea.vue';
import SwecoUpload from '@/components/input/SwecoUpload.vue';
import SwecoForm from '@/components/input/SwecoForm.vue';
import moment from 'moment';
import httpClient from '@/networking/httpclient';
import Form from '@/models/form';
import Config, { Tab } from '@/models/citizenConfig';
import { HttpResponse } from '@/networking/interfaces';
import Issue from '@/models/issue';

@Component({
  components: {
    SwecoTextarea,
    SwecoUpload,
    SwecoForm,
  },
})
export default class SwecoTimeLine extends Vue {
  @Prop({ required: true }) comments!: Comment[];
  @Prop({ default: false }) commentEnabled!: boolean;
  @Prop({ default: false }) fileUploadEnabled!: boolean;
  @Prop({ default: null }) form!: Form;
  @Prop({ default: null }) activeItem!: ActiveItem;

  newMessage: string = '';
  validationError: string = '';
  loading: boolean = false;
  title: string = '';
  responseSuccess: boolean = true;
  formResponseText: string = '';

  @Getter('getAttachments')
  attachments!: Attachments;

  @Getter('getConfig')
  config!: Config;

  @Action('SAVE_ISSUE')
  saveIssueAction!: (issue: Issue) => Promise<HttpResponse>;
  @Action('SAVE_FORM_FIELD_VALUES')
  saveFormFieldValues!: (formFieldValues: FormFieldValues) => Promise<HttpResponse>;

  updateIssue(activeItem: ActiveItem) {
    MessageBus.$emit('loading', true);

    if (activeItem.formFieldValues.fieldValues.length > 0) {
      activeItem.formFieldValues.encryptedEntityId = activeItem.id;
      this.saveFormFieldValues(activeItem.formFieldValues).then(formFieldResponse => {
        if (formFieldResponse) {
          MessageBus.$emit('loading', false);
          this.responseSuccess = true;
          this.$data.responseTitle = this.$t.citizenTranslations.citizenVotesSubmitResponseSuccess + '!';

          this.$toasted.show(this.$data.responseTitle, {
            theme: 'outline',
            position: 'bottom-center',
            duration: 5000,
            fullWidth: true,
            className: 'toastSuccess',
          });
        } else {
          MessageBus.$emit('loading', false);
          this.responseSuccess = false;
          this.$data.responseTitle = this.$t.citizenTranslations.citizenVotesSubmitResponseError + '!';
        }
      });
    }
  }

  get formAvailable() {
    return this.form != null;
  }

  get isNotCompleted() {
    if (this.activeItem.statusId != '2d0aeef4-36a1-45a1-887d-93a5be2d8fe2') {
      return true;
    } else {
      return false;
    }
  }

  getDate(item: Comment) {
    return item.createdDate ? moment(item.createdDate).utc().format('DD') + '.' : '-';
  }
  getMonth(item: Comment) {
    return item.createdDate ? moment(item.createdDate).utc().format('MMM').toUpperCase() : '-';
  }

  getHeading() {
    return `${this.$t.citizenTranslations.citizenDetailsFiles} ( ${this.$t.citizenTranslations.citizenDetailsNoCommentsImageHint} )`;
  }

  sendMessage(event: any) {
    this.validationError = '';
    const upload = this.$refs.upload as Vue & { $el: () => any };
    if (upload != null) {
      upload.$el.setAttribute('aria-invalid', 'false');
    }

    this.loading = false;
    if (this.activeItem) {
      const request = { issueId: localStorage.issueId, comment: this.newMessage, attachments: this.attachments.values };
      httpClient
        .post('citizen/citizencomment', request)
        .then(response => {
          if (response && response.data) {
            this.activeItem.comments = response.data;
            this.loading = false;
            this.attachments.values = [];
          }
        })
        .catch(response => {
          this.validationError = 'Kunne ikke gemme besked, prøv igen.';
          if (upload != null) {
            upload.$el.setAttribute('aria-invalid', 'true');
          }
          this.loading = false;
        });
    }
  }

  sendVote(event: any) {
    this.activeItem.formFieldValues.fieldValues = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.form.containers.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.form.containers[i].fields.length; j++) {
        const field = this.form.containers[i].fields[j];
        if (field.value) {
          if (field.dependsOn) {
            const index = this.activeItem.formFieldValues.fieldValues.findIndex(item => item.fieldId == field.dependsOn.id);
            if (index !== -1) {
              this.activeItem.formFieldValues.fieldValues[index].show = false;
            }
          }
          const fieldValue = new FieldValue();
          fieldValue.fieldId = field.id;
          fieldValue.value = field.value;
          fieldValue.label = field.label;
          fieldValue.show = true;
          if (field.type.controlType.key === 'Date') {
            fieldValue.valueStrings.push(moment(field.value).format('DD-MM-YYYY HH:mm'));
          } else if (field.type.controlType.key === 'Text' || field.type.controlType.key === 'Number') {
            fieldValue.valueStrings.push(field.value);
          } else {
            for (const value of field.type.values) {
              if (fieldValue.value.includes(value.id)) {
                fieldValue.valueStrings.push(value.name);
              }
            }
          }
          this.activeItem.formFieldValues.fieldValues.push(fieldValue);
        }
      }
    }
    this.updateIssue(this.activeItem);
  }
}
