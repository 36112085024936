



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SwecoInput from '@/components/input/SwecoInput.vue';
import { MessageBus } from '@/components/MessageBus';
import { Getter } from 'vuex-class';
import CitizenConfig from '@/models/citizenConfig';
import ActiveItem, { Attachments } from '@/models/activeItem';
import Step1 from '@/components/wizard/Step1.vue';
import Step2 from '@/components/wizard/Step2.vue';
import Step3 from '@/components/wizard/Step3.vue';
import SelectAccessAddress from '@/components/dialogs/SelectAccessAddress.vue';
import ZoomToGeometry from '@/components/dialogs/ZoomToGeometry.vue';
import SwecoForm from '@/components/wizard/SwecoForm.vue';
import AddressObject, { Address } from '@/models/addressObject';
import { LookupIssue } from '@/models/lookupItem';
import { ElementTypeCombined } from '@/models/categoryGroupResponse';
import DawaAddressSearch from '../input/DawaAddressSearch.vue';
import LocalAddressSearch from '../input/LocalAddressSearch.vue';
import IssueList from '@/components/dialogs/IssueList.vue';
import SwecoUpload from '../input/SwecoUpload.vue';
import CitizenLink from '@/components/dialogs/CitizenLink.vue';

@Component({
  components: {
    SwecoInput,
    Step1,
    Step2,
    Step3,
    SelectAccessAddress,
    IssueList,
    ZoomToGeometry,
    CitizenLink,
  },
  validations: {},
})
export default class Wizard extends Vue {
  @Prop({ default: 'wizard' }) type!: string;
  currentTab: number = 1;
  loading: boolean = false;

  showMapIssues: boolean = false;
  showAccessAddress: boolean = false;
  showZoomToGeometry: boolean = false;
  showLink: boolean = false;

  @Getter('getConfig')
  config!: CitizenConfig;

  @Getter('getActiveItem')
  activeItem!: ActiveItem;

  @Watch('config')
  updateActiveItem() {
    this.activeItem.notificationEmail = this.config.mailNotificationPreselected;
  }
  get tabsLength() {
    if (!this.config.tabs) {
      return 1;
    } else {
      return this.config.tabs.length;
    }
  }

  @Watch('activeItem.category')
  showLinkDialog(nextValue: ActiveItem) {
    if (this.activeItem && this.activeItem.category && this.activeItem.category.id) {
      this.showLink = this.activeItem.category.citizenLink.url != '';
    }
  }

  lastStep() {
    this.currentTab -= 1;
    MessageBus.$emit('step', this.currentTab);
    MessageBus.$emit('rightButtonText', this.$t.citizenTranslations.citizenDetailsNext);
  }

  created() {
    MessageBus.$emit('rightButtonText', this.$t.citizenTranslations.citizenDetailsNext);
    MessageBus.$on('loading', (e: boolean) => {
      this.loading = e;
    });
    MessageBus.$on('lastStep', () => {
      this.lastStep();
    });
    MessageBus.$on('nextStep', () => {
      this.nextStep();
    });
  }

  sendMessage(msg: string) {
    const json = JSON.stringify(msg);
    window.parent.postMessage(json, '*');
  }

  mounted() {
    const s1 = this.$refs.step1 as Step1;
    const s2 = this.$refs.step2 as Step2;
    const il = this.$refs.issueList as IssueList;
    const aa = this.$refs.accessAddress as SelectAccessAddress;
    const zg = this.$refs.zoomTo as ZoomToGeometry;

    window.addEventListener('message', (msg: any) => {
      try {
        const result = JSON.parse(msg.data.toString());

        switch (result.event) {
          case 'address':
            if (result.address.locationWkt && result.address.locationWkt != result.address.nearestAddress.address.wkt) {
              result.address.nearestAddress.address.wkt = result.address.locationWkt;
            }
            this.activeItem.addressObject.altLocationNear = '';
            this.activeItem.addressObject.locationNear = result.address.locationNear;
            this.activeItem.addressObject.typeEnum = result.address.nearestAddress.address.typeEnum;
            this.activeItem.addressObject.valid = result.address.valid;
            this.activeItem.addressObject.addressObject = result.address.nearestAddress.address;

            const map = s1.$refs.map as any;
            const msg = '{"event": "addressSelected"}';
            map.contentWindow.postMessage(msg, '*');

            break;

          case 'selectAddress':
            aa.accessAddresses = [];
            // tslint:disable-next-line:prefer-for-of
            for (let index = 0; index < result.altAdresses.length; index++) {
              const altAddress = result.altAdresses[index];
              const address = new AddressObject();
              address.locationNear = altAddress.locationNear;
              address.addressObject = altAddress.addressObject as Address;

              aa.accessAddresses.push(address);
            }
            this.activeItem.addressObject.valid = false;
            this.showAccessAddress = true;

            break;

          case 'openIssueCollectionList':
            il.closeIssue();
            il.mapIssues = [];

            for (const issue of result.issues) {
              const lookupIssue = new LookupIssue();
              lookupIssue.locationNear = issue.locationNear;
              lookupIssue.createdDate = issue.createdDate;
              lookupIssue.name = issue.title;
              lookupIssue.id = issue.id;
              lookupIssue.statusId = issue.statusId;
              il.mapIssues.push(lookupIssue);
            }
            this.showMapIssues = true;

            break;

          case 'issueDetailsPopup':
            il.closeIssue();
            il.mapIssues = [];

            il.openIssue(result.issueId);

            break;

          case 'mouseEventWithoutShift':
            if (result.value) {
              const s1 = this.$refs.step1 as Step1;
              s1.mapAlert = true;
              s2.mapAlert = true;
              setTimeout(() => {
                s1.mapAlert = false;
                s2.mapAlert = false;
              }, 3000);
            }

            break;

          case 'zoomToGeometry':
            const locatioNear = this.config.enabledLocationNear;
            if (locatioNear) {
              this.showZoomToGeometry = true;
              zg.map = s1.$refs.map;
              zg.lon = result.lon;
              zg.lat = result.lat;
            }

            break;
        }
      } catch (e) {
        //
      }
    });
  }

  nextStep() {
    const s1 = this.$refs.step1 as Step1;

    switch (this.currentTab) {
      case 1:
        MessageBus.$emit('validate');

        const sf = s1.$refs.citizenForm as SwecoForm;

        if (!s1.$v.$invalid) {
          if (s1.$refs.citizenForm) {
            //@ts-ignore
            if (sf.anyErrors) {
              break;
            }
          }

          if (
            (s1.$refs.dawaAddressSearch as DawaAddressSearch) &&
            (s1.$refs.dawaAddressSearch as DawaAddressSearch).validationError != ''
          ) {
            break;
          }

          if (
            (s1.$refs.localAddressSearch as LocalAddressSearch) &&
            (s1.$refs.localAddressSearch as LocalAddressSearch).validationError != ''
          ) {
            break;
          }

          if ((s1.$refs.reporterEmail as SwecoInput) && (s1.$refs.reporterEmail as SwecoInput).validationError != '') {
            break;
          }

          if ((s1.$refs.reporterPhone as SwecoInput) && (s1.$refs.reporterPhone as SwecoInput).validationError != '') {
            break;
          }

          if ((s1.$refs.upload as SwecoUpload) && (s1.$refs.upload as SwecoUpload).validationError != '') {
            break;
          }

          this.currentTab += 1;
          MessageBus.$emit('step', this.currentTab);
          MessageBus.$emit('rightButtonText', this.$t.citizenTranslations.citizenDetailsBtnSubmitHint);
          MessageBus.$emit('resetValidation');
          // ts-ignore
          (this.$refs.step2 as Step2).updateIssueMapSrc(this.currentTab);
        }
        break;

      case 2:
        MessageBus.$emit('validate');

        const s2 = this.$refs.step2 as Step2;

        if (!s2.$v.$invalid) {
          this.currentTab += 1;
          MessageBus.$emit('step', this.currentTab);
          MessageBus.$emit('rightButtonText', this.$t.citizenTranslations.citizenDetailsBtnNewHint);
          MessageBus.$emit('resetValidation');
          this.loading = true;
          (this.$refs.step3 as Step3).saveIssue(this.activeItem, this.config);
        }
        break;

      case 3:
        MessageBus.$emit('validate');

        const s3 = this.$refs.step3 as Step3;

        if (!s3.$v.$invalid) {
          this.currentTab = 1;
          MessageBus.$emit('step', this.currentTab);
          MessageBus.$emit('rightButtonText', this.$t.citizenTranslations.citizenDetailsNext);
          MessageBus.$emit('resetValidation');
          this.activeItem.addressObject = new AddressObject();
          this.activeItem.category = new ElementTypeCombined();
          this.activeItem.attachments = new Attachments();
          this.activeItem.description.value = '';
          s1.form = null;
        }
        break;
    }
  }
}
